import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addIdentifier: {
    id: 'COMMON.DIALOGBOX.ADD_INDENTIFER',
    defaultMessage: 'Add Alternate Identifier'
  },
  toastMessageIdentifier: {
    id: 'COMMON.DIALOGBOX.TOAST.MESSAGE.IDENTIFIER',
    defaultMessage: '{message}'
  },
  selectNameDialog: {
    id: 'COMMON.DIALOGBOX.SELECTNAME',
    defaultMessage: 'Select Name'
  },
  addValue: {
    id: 'COMMON.DIALOGBOX.ADD_VALUE',
    defaultMessage: 'Add Value'
  },
  tableValue: {
    id: 'COMMON.DIALOGBOX.TABLE.VALUE',
    defaultMessage: 'Value'
  },
  tableAction: {
    id: 'COMMON.DIALOGBOX.TABLE.ACTION',
    defaultMessage: 'Action'
  },
  identifierErrorMessage: {
    id: 'COMMON.DIALOGBOX.IDENTIFIER_ERROR_MSG',
    defaultMessage: 'Value is required. Entry will be deleted if left blank.'
  }
});
