/* eslint-disable @typescript-eslint/no-shadow */
import { CELL_RENDERERS } from 'cdk-radial';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DriveSelectBox } from 'components/molecules/common/Inputs';
import { messages as dmsAccessMessages } from '../../../../fixtures/grid/dmsAccess/messages';
import {
  ConvertArrToString,
  Options,
  ParamsAcccLogsType,
  ParamsLogsType,
  ParamsProfLogsType
} from './types';

const ConvertArrToDropDown = ({
  data: { profile, id },
  context: { onChangeAccountData }
}: ParamsAcccLogsType): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<any>([]);

  const onChangeValue = (Options: Options[], valueId: string) => {
    setSelectedOption([...Options]);
    onChangeAccountData(Options, valueId);
  };
  return (
    <div style={{ paddingTop: '15px' }}>
      <DriveSelectBox
        uid={id}
        name=""
        options={profile || []}
        selectValue={selectedOption[0]?.value || ''}
        onChangeAddValue={(Options: Options[]) => onChangeValue(Options, id)}
        disabled={false}
      />
    </div>
  );
};

export const useAccountsColumns = (): Array<any> => {
  const intl = useIntl();
  return [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: ParamsLogsType): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(dmsAccessMessages.account),
      minWidth: 150,
      sortable: true
    },
    {
      cellRendererFramework: ConvertArrToDropDown,
      cellRendererParams: (params: ParamsAcccLogsType): any =>
        params.data.profile,
      valueGetter: (params: ParamsAcccLogsType): any => params.data.profile,
      field: 'account',
      headerName: intl.formatMessage(dmsAccessMessages.profileByAccount),
      minWidth: 150
    }
  ];
};

export const useProfilesColumns = (): Array<any> => {
  const intl = useIntl();
  return [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: ParamsProfLogsType): string =>
        params.data.id,
      field: 'id',
      headerName: intl.formatMessage(dmsAccessMessages.profileId),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsProfLogsType): string =>
        params.data.name,
      field: 'name',
      headerName: intl.formatMessage(dmsAccessMessages.name),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: ConvertArrToString,
      valueGetter: (params: ParamsProfLogsType): string | string[] =>
        ConvertArrToString(params),
      cellRendererParams: (params: ParamsProfLogsType): string =>
        params.data.apps,
      field: 'id',
      headerName: intl.formatMessage(dmsAccessMessages.apps),
      minWidth: 150,
      sortable: true
    }
  ];
};

export const useOrganizationColumns = (): Array<any> => {
  const intl = useIntl();
  return [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: ParamsLogsType): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(dmsAccessMessages.id),
      minWidth: 150,
      sortable: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      cellRendererParams: (params: ParamsLogsType): string => params.data.name,
      field: 'name',
      headerName: intl.formatMessage(dmsAccessMessages.organization),
      minWidth: 150,
      height: 100,
      sortable: true
    }
  ];
};
