/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  MainSection,
  Toast,
  TOAST_VISIBILITY_DURATIONS,
  TOAST_POSITIONS
} from 'cdk-radial';
import { SessionTimeoutDialog } from 'components/common/Dialogs';
import Routes from 'components/router/root';
import SideNav from 'components/organisms/common/SideNav/SideNav';
import { ContentHeader } from 'components/common/ContentHeader';
import useIdleTimer from 'hooks/useIdleTimer';
import { messages as commonMessages } from 'commonMessages';
import { RootState } from 'redux/store';
import { resetGlobalToast } from 'redux/slices/commonSlice';
import {
  HarnessFlagProvider,
  AmplitudeProvider,
  WalkmeProvider,
  ClientProvider
} from 'providers';
import { StyledShellContainer } from './styled';
import {
  OKTA_SESSION_TIMEOUT,
  COUNTDOWN_DURATION,
  LOG_OUT_URL
} from './constants';
import { messages } from '../messages';

const AppWrapper = (): JSX.Element => {
  const oktaHook = useOktaAuth();
  const { authState } = useOktaAuth();
  const oktaToken = authState?.accessToken?.accessToken || '';
  localStorage.setItem('okta-token', oktaToken);
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isIdle, setIsIdle] = useState<boolean>(false);

  const {
    commonState: { toast, isImpersonationActiveForCurrentEnterprise }
  } = useSelector((state: RootState) => state.slices);

  const onIdle = () => {
    setIsIdle(true);
  };

  useIdleTimer(OKTA_SESSION_TIMEOUT, onIdle);

  const logout = async () => {
    const token = oktaHook.oktaAuth.getAccessToken();
    if (token) {
      try {
        oktaHook.oktaAuth.tokenManager.clear();
        localStorage.clear();
        await oktaHook.oktaAuth.signOut({
          postLogoutRedirectUri: LOG_OUT_URL,
          clearTokensBeforeRedirect: true
        });
      } catch (error) {
        window.location.assign(LOG_OUT_URL);
      }
    } else {
      window.location.assign(LOG_OUT_URL || '');
    }
  };

  const clearExpiredSession = useCallback(async () => {
    const isSessionExist = await oktaHook.oktaAuth.isAuthenticated();
    if (!isSessionExist) localStorage.clear();
    return !isSessionExist;
  }, [oktaHook?.oktaAuth]);

  useEffect(() => {
    if (oktaHook.authState) {
      clearExpiredSession();
    }
  }, [clearExpiredSession, oktaHook.authState]);

  const handleStaySignedIn = async () => {
    const isSessionExpired = await clearExpiredSession();
    if (isSessionExpired) history.replace('/');
    setIsIdle(false);
  };

  const handleLogout = () => {
    setIsIdle(false);
    logout();
  };

  const TOAST_ACTIONS = [
    {
      onClick: () => {
        dispatch(resetGlobalToast());
      },
      text: intl.formatMessage(messages.toasterDismiss)
    }
  ];

  const TOAST_AUTO_HIDE = toast.autoHide
    ? {
        onVisibilityDurationEnd: () => dispatch(resetGlobalToast()),
        visibilityDuration: TOAST_VISIBILITY_DURATIONS.SIX_SECONDS
      }
    : {};

  const mainSectionCSSClass = isImpersonationActiveForCurrentEnterprise
    ? 'main-section-impersonation'
    : 'main-section';

  return (
    <StyledShellContainer>
      <ClientProvider>
        <WalkmeProvider />
        <AmplitudeProvider>
          <HarnessFlagProvider>
            <ContentHeader />
            <SideNav />
            <MainSection className={mainSectionCSSClass}>
              <div className="card-div">
                {toast.showToast && (
                  <Toast
                    className="global-toast"
                    content={toast.toastContent}
                    variant={toast.toastVariant}
                    id="global-toast"
                    position={TOAST_POSITIONS.FIXED}
                    actions={TOAST_ACTIONS}
                    {...TOAST_AUTO_HIDE}
                  />
                )}
                <Routes />
              </div>
            </MainSection>
            {isIdle && (
              <SessionTimeoutDialog
                title={intl.formatMessage(
                  commonMessages.sessionTimeoutWarningTitle
                )}
                description={intl.formatMessage(
                  commonMessages.sessionTimeoutWarningMessage
                )}
                countdownDuration={COUNTDOWN_DURATION}
                handleLogout={handleLogout}
                handleStayIn={handleStaySignedIn}
                primaryButtonText={intl.formatMessage(commonMessages.logout)}
                secondaryButtonText={intl.formatMessage(
                  commonMessages.staySignedInButton
                )}
                hideCloseButton
              />
            )}
          </HarnessFlagProvider>
        </AmplitudeProvider>
      </ClientProvider>
    </StyledShellContainer>
  );
};
export default AppWrapper;
