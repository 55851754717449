/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export const saveExternalIdentifierData = (
  data: any,
  selectedName: string,
  ipaddress: string,
  handleSave: (data: any) => void
): void => {
  const dmsStateValue: any = data[0] && data[0]?.tableData;
  const oldIpaddress: string =
    dmsStateValue && dmsStateValue?.driveIpAddress?.[0]?.externalSystemId;
  const oldDmsCnumber: string =
    dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId;

  const dataToSend: any = {
    option: selectedName,
    data: ipaddress,
    oldValues: { option: oldIpaddress, value: oldDmsCnumber }
  };
  handleSave(dataToSend);
};
const returnBooleanStatus = (
  arr: Record<string, unknown>[],
  errorInputs: Record<number, unknown>[],
  setErrorInputs: any
) => {
  let flag = true;
  arr.forEach((item: any, index) => {
    const num: any = index;
    if (!item) {
      errorInputs.push(num);
      flag = false;
    }
  });
  setErrorInputs([...errorInputs]);
  return flag;
};

export const addModalSaveData = (
  indentyTableData: any,
  newIndentityData: any,
  selectedName: any,
  tableData: any,
  handleSave: (data: any) => void,
  handleModalClose: () => void,
  errorInputs: Record<number, unknown>[],
  setErrorInputs: any
): boolean => {
  let typeStatus = '';
  let save = false;
  if (indentyTableData && indentyTableData.length > 0) {
    typeStatus = 'edit';
  } else {
    typeStatus = 'add';
  }

  if (typeStatus === 'edit') {
    const arryData: any = indentyTableData[0];
    save = returnBooleanStatus(arryData, errorInputs, setErrorInputs);
  } else {
    save = returnBooleanStatus(newIndentityData, errorInputs, setErrorInputs);
  }
  if (!selectedName && !tableData[0]) {
    return false;
  }
  if (indentyTableData.length > 0 || newIndentityData.length > 0) {
    if (save) {
      const optionname: any =
        (selectedName && selectedName.value) || tableData[0];

      const dataToSend: any = {
        option: optionname,
        status: typeStatus,
        data:
          (indentyTableData.length > 0 && indentyTableData) ||
          (newIndentityData.length > 0 && newIndentityData)
      };
      handleSave(dataToSend);
      handleModalClose();
    }
  }
  return true;
};

export const addCNumberModalSaveData = (
  tableData: any,
  indentyTableData: any,
  newIndentityData: any,
  errorInputs: Record<number, unknown>[],
  setErrorInputs: any,
  selectedName: any,
  deleteIdentyTableData: any,
  getCNumberValidCheck: (payload: any) => any,
  text: string | JSX.Element,
  putDeleteAlternateDetails: (payload: any) => any,
  handleModalClose: () => void,
  handleSave: (data: any) => void,
  setDialogBoxError: any,
  refetch: () => void
) => {
  let typeStatus = '';
  let save = false;
  let updateddata: any = [];
  const oldValues: string[] = [];
  const dmsStateValue: any = tableData[1];
  const editData: any = indentyTableData[0];

  if (indentyTableData && indentyTableData.length > 0) {
    typeStatus = 'edit';
  } else {
    typeStatus = 'add';
  }

  if (typeStatus === 'edit') {
    save = returnBooleanStatus(editData, errorInputs, setErrorInputs);
    dmsStateValue.forEach((a: any) => {
      oldValues.push(a.externalSystemId);
    });
    updateddata =
      oldValues.length > editData.length
        ? oldValues.filter((e: string) => !editData.includes(e))
        : editData.filter((e: string) => !oldValues.includes(e));
  } else {
    save = returnBooleanStatus(newIndentityData, errorInputs, setErrorInputs);
  }
  if (!selectedName) {
    return false;
  }

  const enteredValue: Record<string, unknown>[] =
    typeStatus === 'add' ? newIndentityData : updateddata;

  if (deleteIdentyTableData.length > 0) {
    deleteIdentyTableData.forEach((a: any) => {
      getCNumberValidCheck(a)
        .then((resp: Record<string, any>) => {
          if (!('error' in resp) && resp?.data.dmsBoxes.length > 0) {
            const body = {
              dmsValue: a[0],
              ipaddress: resp.data.dmsBoxes[0].ipAddress,
              storeId: text
            };
            putDeleteAlternateDetails(body)
              .then((respo: Record<string, any>) => {
                if (!('error' in respo)) {
                  setDialogBoxError('Deleted successfully');
                  setTimeout(handleModalClose, 1000);
                } else {
                  let errMsg: string;
                  if ('data' in respo.error) {
                    errMsg = respo.error.data?.error?.message;
                    setDialogBoxError(
                      errMsg
                        ? `${errMsg}`
                        : 'User Not Authorized to perform this Action'
                    );
                  }
                }
              })
              .catch((err: Error) => {
                setDialogBoxError(err.toString());
              });
          } else {
            let errMsg: string;
            if (resp.error && 'data' in resp.error) {
              errMsg = resp.error?.data?.error?.message;
              setDialogBoxError(
                errMsg
                  ? `${errMsg}`
                  : 'User Not Authorized to perform this Action'
              );
            } else {
              errMsg = 'Invalid CNumber';
              setDialogBoxError(errMsg);
            }
          }
        })
        .catch((err: Error) => {
          setDialogBoxError(err.toString());
        });
    });
  }
  enteredValue.forEach((a: any) => {
    getCNumberValidCheck(a)
      .then((resp: Record<string, any>) => {
        if (
          !('error' in resp) &&
          resp?.data.dmsBoxes.length > 0 &&
          (indentyTableData.length > 0 || newIndentityData.length > 0) &&
          save
        ) {
          const optionname: any =
            (selectedName.label && selectedName) || tableData[0];

          const dataToSend: any = {
            option: optionname,
            ipaddress: resp.data.dmsBoxes[0].ipAddress || '',
            status: typeStatus,
            cNumber: resp.data.dmsBoxes[0].cnumber || '',
            data: 'cNumber',
            oldData: dmsStateValue,
            storedData:
              (updateddata.length > 0 && updateddata) ||
              (newIndentityData.length > 0 && newIndentityData)
          };
          handleModalClose();
          handleSave(dataToSend);
        } else {
          let errMsg: string;
          if (resp.error && 'data' in resp.error) {
            errMsg = resp.error?.data?.error?.message;
            setDialogBoxError(
              errMsg
                ? `${errMsg}`
                : 'User Not Authorized to perform this Action'
            );
          } else {
            errMsg = 'Invalid CNumber';
            setDialogBoxError(errMsg);
          }
        }
      })
      .catch((err: Error) => {
        setDialogBoxError(err.toString());
      });
  });
  refetch();
  return true;
};
