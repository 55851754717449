import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  btnExitSave: {
    id: 'COMMON.DIALOG.BUTTON.TITLE.EXITSAVE',
    defaultMessage: 'Save & Exit'
  },
  exitSaveContent: {
    id: 'COMMON.DIALOGBOX.EXITSAVE.CONTENT',
    defaultMessage: '{text}'
  }
});
