import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  fieldError: {
    id: 'HELPER.NEWUSERHELPER.FIELD.ERROR.MESSAGE',
    defaultMessage: 'This Field is Mandatory'
  },
  simpleIdError: {
    id: 'HELPER.NEWUSERHELPER.SIMPLEID.ERROR.MESSAGE',
    defaultMessage:
      'Special characters “%”, “#”, “^”, “&” are not allowed in CDK Simple ID'
  },
  simpleIdValidation: {
    id: 'HELPER.NEWUSERHELPER.SIMPLEID.VALIDATION.MESSAGE',
    defaultMessage: 'Field is in invalid format'
  },
  errorFixToast: {
    id: 'HELPER.NEWUSERHELPER.ERRORFIX.TOAST.MESSAGE',
    defaultMessage: 'Please fix the errors in the page'
  },
  phoneNumberError: {
    id: 'HELPER.NEWUSERHELPER.PHONENUMBER.ERROR.MESSAGE',
    defaultMessage: 'There should be 10 digits for the phone Number'
  },
  passwordError: {
    id: 'HELPER.NEWUSERHELPER.PASSWORD.ERROR.MESSAGE',
    defaultMessage:
      'Password must contain atleast 8 characters, 1 number, both lower and uppercase letters and special characters'
  },
  passwordValidation: {
    id: 'HELPER.NEWUSERHELPER.PASSWORD.VALIDATION.MESSAGE',
    defaultMessage: 'Passwords do not match'
  },
  csvValidationError: {
    id: 'HELPER.CSVHELPER.VALIDATION.TOAST.ERROR.MESSAGE',
    defaultMessage:
      'Please add these missing headers in the csv: {missingHeaders}'
  },
  storesLimitExceedError: {
    id: 'HELPER.NEWUSERHELPER.VALIDATION.STORES.LIMIT.TOAST.ERROR.MESSAGE',
    defaultMessage: 'Please select less than 50 stores at a time.'
  },
  rolesLimitExceedError: {
    id: 'HELPER.NEWUSERHELPER.VALIDATION.ROLES.LIMIT.TOAST.ERROR.MESSAGE',
    defaultMessage: 'Please select less than 50 roles at a time.'
  }
});
