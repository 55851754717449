import { useIntl } from 'react-intl';
import { Dialog, BUTTON_VARIANTS } from 'cdk-radial';
import { messages as commonMessages } from 'commonMessages';
import { ExitSaveDialogProps } from './types';
import { messages } from './messages';

export const ExitSaveDialog = ({
  isOpen,
  handleClose,
  handleSave,
  text
}: ExitSaveDialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <div data-testid="saveexit-dialogBox-page">
      <Dialog
        dataTestId="exitsave"
        style={{ width: '24.21875vw' }}
        disableOverlayClicked={false}
        id="dialog-2"
        isOpen={isOpen}
        onClose={() => handleClose('exit')}
        title={intl.formatMessage(commonMessages.cancel)}
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: handleSave,
            text: intl.formatMessage(messages.btnExitSave),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
      >
        <p>{intl.formatMessage(messages.exitSaveContent, { text })}</p>
      </Dialog>
    </div>
  );
};

export default ExitSaveDialog;
