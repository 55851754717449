import styled from 'styled-components';
import { CardGroup, CardRow, CardTitle, CardWrapper, Row } from 'cdk-radial';

const StyledCardWrapper = styled(CardWrapper)`
  margin-bottom: 16px;
  box-shadow: none;
  border: 1px solid #c7c7c7;
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  padding-right: 4rem;

  h1 {
    padding-right: 2px;
  }
`;

const StyledCardGroup = styled(CardGroup)`
  padding-right: 4rem;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    padding-right: 2px;
  }
`;

const StyledCardRow = styled(CardRow)`
  padding-right: 4rem;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    padding-right: 2px;
  }
`;

const StyledCardTitle = styled(CardTitle)`
  padding-bottom: 0.5rem;
`;
const StyledRow = styled(Row)`
  display: flex;
  justify-content: start;
  padding-bottom: 30px;
`;

const StyledInputBoxContainer = styled.div`
  width: '125px';
`;

export {
  StyledCardContent,
  StyledCardGroup,
  StyledCardRow,
  StyledCardTitle,
  StyledCardWrapper,
  StyledRow,
  StyledInputBoxContainer
};
