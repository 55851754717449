import { Dialog, Button, BUTTON_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { messages as commonMessages } from 'commonMessages';
import { messages } from './messages';
import StyledDeleteDialog, { StyledCancelButton } from './styled';
import { DeleteDialogProps } from './type';

export const DeleteDialog = ({
  title,
  description,
  handleDelete,
  handleClose
}: DeleteDialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <Dialog
      dataTestId="delete-dialog"
      id="delete-dialog"
      className="delete-dialog"
      isOpen
      onClose={() => handleClose(false)}
      title={title || intl.formatMessage(messages.deleteTitle)}
    >
      <StyledDeleteDialog>
        <div
          className="delete-dialog-description"
          data-testid="delete-dialog-description"
        >
          {description || intl.formatMessage(messages.deleteDialogDescription)}
        </div>

        <div className="dialog-footer">
          <div className="footer-button-group">
            <StyledCancelButton
              dataTestId="cancel-btn"
              text={intl.formatMessage(commonMessages.cancel)}
              variant={BUTTON_VARIANTS.TEXT}
              onClick={() => handleClose(false)}
            />
            <Button
              dataTestId="delete-btn"
              text={intl.formatMessage(commonMessages.delete)}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={handleDelete}
            />
          </div>
        </div>
      </StyledDeleteDialog>
    </Dialog>
  );
};
