import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AgGridColumnProps } from 'ag-grid-react';
import { Radio } from 'cdk-radial';
import useEnterpriseId from 'hooks/useEnterpriseId';
import {
  CustomAddress,
  CustomLinkRenderProps,
  ParamsStore,
  Params,
  PrimaryStoreRadioButtonProps,
  Store
} from './type';
import { messages } from './messages';
import { GROUP_COLUMN } from './constants';

export const CustomCityRenderer = ({
  data: { addresses }
}: ParamsStore): string => {
  if (addresses) {
    const cityData = addresses.find(
      ({ addressType }) => addressType === GROUP_COLUMN.PHYSICAL
    );

    const cityString: string = (cityData && cityData?.city) || '';
    return cityString;
  }
  return '';
};

export const CustomCountryRenderer = ({
  data: { addresses }
}: ParamsStore): string => {
  if (addresses) {
    const countryData = addresses.find(
      ({ addressType }) => addressType === GROUP_COLUMN.PHYSICAL
    );

    const countryString: string = (countryData && countryData?.country) || '';
    return countryString;
  }
  return '';
};

export const CustomTimeZoneRenderer = ({
  data: { timeZone }
}: ParamsStore): string => {
  if (timeZone) {
    return timeZone || '';
  }
  return '';
};

const CustomLinkRenderer = ({ value }: { value: string }): JSX.Element => {
  let type = '';
  const enterpriseId = useEnterpriseId();
  const enterprisePath = `enterprise/${enterpriseId}`;
  const selectId: string = value;
  const firstLetter = selectId.slice(0, 1);
  if (firstLetter === 'G') {
    type = 'view_group';
  } else if (firstLetter === 'D') {
    type = 'department';
  } else {
    type = 'store';
  }
  const target = `/${enterprisePath}/${type}/${value}`;
  return (
    <Link style={{ color: '#006cfa', textDecoration: 'none' }} to={target}>
      {value}
    </Link>
  );
};

const PrimaryStoreRadioButtonRender = ({
  data: { id, type, primaryStore },
  context: { onSelectionChanged }
}: PrimaryStoreRadioButtonProps): JSX.Element => (
  <>
    {type === GROUP_COLUMN.STORE && (
      <Radio
        dataTestId="primaryStore-radioButton"
        id={id}
        checked={primaryStore}
        name="primary"
        onChange={() => onSelectionChanged(id)}
        value={id}
      />
    )}
  </>
);

const CustomEntityCountRenderer = ({
  data: { organizationUnits }
}: Params): string => {
  if (organizationUnits) {
    const count = organizationUnits ? organizationUnits.length : 0;
    return `${count}`;
  }
  return '0';
};

const useColumns = (tab: string): Array<AgGridColumnProps> => {
  const intl = useIntl();
  const newGroupColoumns: AgGridColumnProps[] = [
    {
      cellRendererFramework: CustomLinkRenderer,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: Params): CustomLinkRenderProps =>
        params.data,
      valueGetter: (params: Params): string => params.data?.id || '',
      field: 'id',
      headerName: intl.formatMessage(messages.groupId),
      sortable: true
    },
    {
      field: 'name',
      headerName: intl.formatMessage(messages.groupName),
      wrapText: true
    },
    {
      field: 'type',
      headerName: intl.formatMessage(messages.type)
    },
    {
      cellRenderer: CustomEntityCountRenderer,
      cellRendererParams: (params: Params): string[] =>
        params.data.organizationUnits,
      valueGetter: (params: Params) => CustomEntityCountRenderer(params),
      field: 'entitiyCount',
      headerName: intl.formatMessage(messages.entityCount),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      sortable: true
    }
  ];

  const viewGroupColumns: AgGridColumnProps[] = [
    {
      cellRendererFramework: CustomLinkRenderer,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: Params): any => params.data,
      valueGetter: (params: Params): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(messages.entityId),
      minWidth: 100,
      sortable: true
    },
    {
      field: 'name',
      headerName: intl.formatMessage(messages.entityName),
      minWidth: 170,
      sortable: true,
      wrapText: true,
      cellStyle: { 'white-space': 'normal', 'line-height': '20px' }
    },
    {
      field: 'type',
      headerName: intl.formatMessage(messages.entityType),
      minWidth: 170,
      sortable: true
    },
    {
      cellRendererFramework: PrimaryStoreRadioButtonRender,
      valueGetter: (params: Store): string => params.data.type,
      field: 'defaultStore',
      headerName: intl.formatMessage(messages.primaryStore),
      minWidth: 150,
      sortable: true
    }
  ];

  const storeColumns: AgGridColumnProps[] = [
    {
      cellRendererFramework: CustomLinkRenderer,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRendererParams: (params: Params): any => params.data,
      valueGetter: (params: Params): string => params.data.id,
      field: 'id',
      headerName: intl.formatMessage(messages.storeId),
      minWidth: 100,
      sortable: true
    },
    {
      field: 'name',
      headerName: intl.formatMessage(messages.storeName),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CustomCityRenderer,
      cellRendererParams: (params: ParamsStore): CustomAddress[] =>
        params.data.addresses,
      valueGetter: (params: ParamsStore): string | string[] =>
        CustomCityRenderer(params),
      field: 'addresses',
      headerName: intl.formatMessage(messages.storeCity),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      sortable: true
    },
    {
      cellRenderer: CustomCountryRenderer,
      cellRendererParams: (params: ParamsStore): CustomAddress[] =>
        params.data.addresses,
      valueGetter: (params: ParamsStore): string | string[] =>
        CustomCountryRenderer(params),
      field: 'addresses',
      headerName: intl.formatMessage(messages.storeCountry),
      cellStyle: { 'white-space': 'normal' },
      autoHeight: true,
      sortable: true
    },
    {
      field: 'type',
      headerName: intl.formatMessage(messages.storeType),
      minWidth: 170,
      sortable: true
    },
    {
      cellRenderer: CustomTimeZoneRenderer,
      valueGetter: (params: ParamsStore): string =>
        CustomTimeZoneRenderer(params),
      field: 'timeZone',
      headerName: intl.formatMessage(messages.storeTimeZone),
      minWidth: 170,
      sortable: true
    }
  ];

  const COLUMN_DEFINATION: Record<string, AgGridColumnProps[]> = {
    Store: storeColumns,
    Department: storeColumns,
    Group: newGroupColoumns,
    ViewGroup: viewGroupColumns
  };

  return COLUMN_DEFINATION[tab];
};

export default useColumns;
