/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { Input, Row } from 'cdk-radial';
import StyledInputBoxs from './styled';
import { messages } from './messages';

interface Props {
  uid: string | number;
  name: any;
  typeValue: string;
  disabled: boolean;
  onChangeAddValue: (event: FormEvent<HTMLFormElement>, title: string) => void;
  errorText: boolean;
  required?: boolean | false;
}
const DriveTextBox = ({
  uid,
  name,
  typeValue,
  disabled,
  onChangeAddValue,
  errorText,
  required = false
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledInputBoxs data-testid="drive-textbox">
      <Row>
        <Input
          id={uid.toString()}
          label={name}
          name={name}
          onChange={onChangeAddValue}
          placeholder={`Enter ${name}`}
          value={typeValue || ''}
          isDisabled={disabled}
          data-testid="drive-input-box"
          enableCustomValidation
          hasError={errorText}
          isRequired={required}
          errorMessage={
            name === 'Domain'
              ? intl.formatMessage(messages.driveTextBoxError)
              : intl.formatMessage(messages.driveTextBoxRequiredField)
          }
        />
      </Row>
    </StyledInputBoxs>
  );
};
export default DriveTextBox;
