import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole, UserStore } from 'redux/services/admin-service/types';
import { FieldNameType } from '../../fixtures/NewUser/types';
import UserDetailsType, {
  SectionType as EditUserSectionType,
  FieldNameType as EditUserFieldType
} from '../../fixtures/EditUser/types';
import userDetailsObject from '../../fixtures/NewUser/NewUserDetails';
import {
  DMSProfile,
  DMSProfilePayload,
  DMSProfileStepperPayload,
  HandleAssignment,
  HandleEditFieldChange,
  HandleErrorPayload,
  HandleFieldChange,
  HandleFieldRequired,
  HandleUnAssignment,
  HandleUnAssignmentUser,
  JobTitlesPayload,
  UserManagementState,
  HandleBulkUnassign,
  SearchDetailsPayload
} from './types/userManagementSliceTypes';

const dmsProfileInitialState = {
  showDMSProfileStepper: false,
  isDMSProfileCopied: false,
  isNewDMSUser: true,
  cnumberOfSourceDMS: '',
  dmsUserId: '',
  validations: {}
} as DMSProfile;

export const initialState: UserManagementState = {
  userDetails: userDetailsObject || null,
  userEdit: null,
  userEditRoles: [{} as UserRole],
  userEditStores: [{} as UserStore],
  dmsProfile: dmsProfileInitialState,
  searchDetails: {} as SearchDetailsPayload
};

const transitionData = (data: Record<string, unknown>[], id: string) => {
  const pushData: Record<string, unknown>[] = [];
  data.forEach(a => {
    const idValue = id === 'roleGuid' ? a.roleGuid : a.storeId;
    pushData.push({
      [id]: idValue,
      assignmentDate: a.modifiedDate,
      assignedBy: a.modifiedBy,
      code: a.code
    });
  });
  return pushData;
};

export const userManagementSlice = createSlice({
  name: 'userManagementState',

  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetailsType | null>) => {
      state.userEdit = action.payload;
    },
    resetUserInfo: state => {
      state.userEdit = null;
    },
    handleFieldChange: (
      state: any,
      action: PayloadAction<HandleFieldChange>
    ): void => {
      const { section, field, value } = action.payload;
      if (
        (field === FieldNameType.locale || field === FieldNameType.suffix) &&
        value
      ) {
        state.userDetails[section][field].value.value = value;
      } else {
        state.userDetails[section][field].value = value;
      }

      if (field === FieldNameType.externallyFederated && value) {
        state.userDetails[section][
          FieldNameType.uniquePrincipalName
        ].isRequired = JSON.parse(value);
      }
    },
    handleFieldRequired: (
      state: any,
      action: PayloadAction<HandleFieldRequired>
    ): void => {
      const { section, field, value } = action.payload;
      state.userDetails[section][field].isRequired = value;
    },
    handleAssignment: (
      state,
      action: PayloadAction<HandleAssignment>
    ): void => {
      const { selectedItems, field, loginId } = action.payload;
      // eslint-disable-next-line
      const attachedUserInfo = selectedItems.map(selectedItem => ({
        ...selectedItem,
        assignmentDate: moment(),
        assignedBy: loginId || ''
      }));
      // eslint-disable-next-line
      const combinedItems = [...attachedUserInfo, ...state.userDetails[field]];
      const keyToAccess = field === 'stores' ? 'id' : 'code';
      // eslint-disable-next-line
      const uniqueItems = combinedItems.filter(
        (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
          a.findIndex(t => t[keyToAccess] === v[keyToAccess]) === i
      );
      state.userDetails[field] = uniqueItems;
    },
    handleUnAssignItem: (
      state,
      action: PayloadAction<HandleUnAssignmentUser>
    ) => {
      const { field, rowIndex } = action.payload;
      state.userDetails[field] = state.userDetails[field].filter(
        (item, index) => rowIndex !== index
      );
    },
    setError: (state: any, action: PayloadAction<HandleErrorPayload>) => {
      const { section, field, hasError, errorMessage } = action.payload;
      state.userDetails[section][field].hasError = hasError;
      state.userDetails[section][field].errorMessage = errorMessage;
    },
    setJobTitles: (state, action: PayloadAction<JobTitlesPayload>) => {
      const { jobTitles } = action.payload;
      state.userDetails.identificationDetails.jobTitle.options = jobTitles;
    },
    setEditUserDetails: (
      state,
      action: PayloadAction<UserDetailsType | null>
    ) => {
      state.userEdit = action.payload;
    },
    handleEditFieldChange: (
      state: any,
      action: PayloadAction<HandleEditFieldChange>
    ): void => {
      const { section, field, value } = action.payload;

      if (
        section === EditUserSectionType.alternateIdentifiers &&
        field === EditUserFieldType.alternateIdentifiers
      ) {
        const combinedItems = [...state.userEdit[section], value];
        state.userEdit[section] = combinedItems;
      } else if (
        field === EditUserFieldType.externallyFederated ||
        field === EditUserFieldType.accountStatus ||
        field === EditUserFieldType.mfaEnabled
      ) {
        state.userEdit[section][field] = value;
      } else {
        state.userEdit[section][field].value = value;
      }
    },
    handleEditUserAssignment: (
      state,
      action: PayloadAction<HandleAssignment>
    ): void => {
      const { selectedItems, field, loginId } = action.payload;
      // eslint-disable-next-line
      const attachedUserInfo = selectedItems.map(selectedItem => ({
        ...selectedItem,
        assignmentDate: moment(),
        assignedBy: loginId || ''
      }));
      // eslint-disable-next-line
      const combinedItems = [...attachedUserInfo, ...state.userDetails[field]];
      const keyToAccess = field === 'stores' ? 'id' : 'code';
      // eslint-disable-next-line
      const uniqueItems = combinedItems.filter(
        (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
          a.findIndex(t => t[keyToAccess] === v[keyToAccess]) === i
      );
      state.userDetails[field] = uniqueItems;
    },
    handleEditUnlink: (state, action: PayloadAction<any>): void => {
      const { row } = action.payload;
      if (state.userEdit) {
        state.userEdit.alternateIdentifiers =
          state.userEdit.alternateIdentifiers.filter(
            (item, index) => row !== index
          );
      }
    },
    handleEditUserUnAssignItem: (
      state,
      action: PayloadAction<HandleUnAssignmentUser>
    ) => {
      const { field, rowIndex } = action.payload;
      state.userDetails[field] = state.userDetails[field].filter(
        (item, index) => rowIndex !== index
      );
    },
    setEditUserRoles: (state, action: PayloadAction<any>) => {
      state.userEditRoles = action.payload;
    },
    setEditUserStores: (state, action: PayloadAction<any>) => {
      state.userEditStores = action.payload;
    },
    handleAccessRoleAssignments: (
      state,
      action: PayloadAction<HandleAssignment>
    ): void => {
      const { selectedItems, loginId } = action.payload;
      // eslint-disable-next-line
      const attachedUserRoles = selectedItems.map(item => ({
        code: item.code,
        modifiedBy: loginId,
        modifiedDate: Date.now(),
        parentRoleGuid: '7ff34e9969074456b9a6daee75a47d87',
        roleGuid: item.roleGuid
      }));
      const combinedItems = [...attachedUserRoles, ...state.userEditRoles];
      // eslint-disable-next-line
      const keyToAccess = 'code';
      // eslint-disable-next-line
      // const uniqueItems = combinedItems.filter((v: Record<string, any>, i: number, a: Record<string, any>[]) => a.findIndex((t) => (t[keyToAccess] === v[keyToAccess])) === i);
      state.userEditRoles = combinedItems;
    },
    handleAccessBulkRoleUnassign: (
      state,
      action: PayloadAction<HandleBulkUnassign>
    ): void => {
      const { selectedItems, loginId } = action.payload;
      const userRoles = state.userEditRoles.map(item => {
        if (
          selectedItems &&
          selectedItems.length > 0 &&
          selectedItems[0] &&
          item.roleGuid === selectedItems[0].roleGuid
        ) {
          return {
            ...item,
            code: item.code,
            modifiedBy: loginId,
            roleGuid: item.roleGuid,
            modifiedDate: Date.now()
          };
        }
        return {
          ...item
        };
      });

      const combinedRoles = [...userRoles];
      state.userEditRoles = combinedRoles;
    },
    handleAccessBulkStoreUnassign: (
      state,
      action: PayloadAction<HandleBulkUnassign>
    ): void => {
      const { selectedItems, loginId } = action.payload;
      const userRoles = state.userEditRoles.map(item => {
        if (
          selectedItems &&
          selectedItems.length > 0 &&
          selectedItems[0] &&
          item.roleGuid === selectedItems[0].roleGuid
        ) {
          return {
            ...item,
            code: item.code,
            modifiedBy: loginId,
            roleGuid: item.roleGuid,
            modifiedDate: Date.now()
          };
        }
        return {
          ...item
        };
      });

      const combinedRoles = [...userRoles];
      state.userEditRoles = combinedRoles;
    },
    handleAccessStoreAssignments: (
      state,
      action: PayloadAction<HandleAssignment>
    ): void => {
      const { selectedItems, loginId } = action.payload;
      // eslint-disable-next-line
      const stores = selectedItems.map(item => ({
        defaultStore: false,
        modifiedBy: loginId,
        modifiedDate: Date.now(),
        storeId: item.id as string
      }));
      const combinedItems = [...stores, ...state.userEditStores];
      // eslint-disable-next-line
      const keyToAccess = 'id';
      // eslint-disable-next-line
      //   const uniqueItems = combinedItems.filter((v: Record<string, any>, i: number, a: Record<string, any>[]) => a.findIndex((t) => (t[keyToAccess] === v[keyToAccess])) === i);
      state.userEditStores = combinedItems;
    },
    handleAccessRoleUnAssignItem: (
      state,
      action: PayloadAction<HandleUnAssignment>
    ) => {
      const { id } = action.payload;
      state.userEditRoles = state.userEditRoles.filter(
        (item: any) => !id.includes(item.code)
      );
    },
    handleAccessStoreUnAssignItem: (
      state,
      action: PayloadAction<HandleUnAssignment>
    ) => {
      const { id } = action.payload;
      state.userEditStores = state.userEditStores.filter(
        (item: any) => !id.includes(item.code)
      );
    },
    resetUserForm: (state: any) => {
      state.userDetails = userDetailsObject;
    },
    updateStoreandRoles: (state: any, data: any) => {
      state.userDetails.roles = transitionData(data.payload.roles, 'roleGuid');
      state.userDetails.stores = transitionData(data.payload.stores, 'id');
    },
    handleDMSProfile: (state, action: PayloadAction<DMSProfilePayload>) => {
      const { field, value } = action.payload;
      state.dmsProfile[field] = value;
    },
    handleDMSProfileSteper: (
      state,
      action: PayloadAction<DMSProfileStepperPayload>
    ) => {
      const { field, value } = action.payload;
      state.dmsProfile[field] = value;
    },
    resetDMSProfile: state => {
      state.dmsProfile = {
        ...dmsProfileInitialState,
        isNewDMSUser: state.dmsProfile.isNewDMSUser
      };
    },
    setSearchDetails: (state, action: PayloadAction<SearchDetailsPayload>) => {
      const { searchValue, searchId } = action.payload;
      state.searchDetails = {
        searchValue,
        searchId
      };
    }
  }
});

export const {
  setUserDetails,
  handleFieldChange,
  handleFieldRequired,
  handleAssignment,
  handleUnAssignItem,
  setError,
  setJobTitles,
  setEditUserDetails,
  handleEditFieldChange,
  handleEditUserAssignment,
  handleEditUserUnAssignItem,
  handleEditUnlink,
  setEditUserRoles,
  setEditUserStores,
  handleAccessRoleAssignments,
  handleAccessRoleUnAssignItem,
  handleAccessStoreAssignments,
  handleAccessStoreUnAssignItem,
  resetUserInfo,
  updateStoreandRoles,
  resetUserForm,
  handleDMSProfile,
  handleDMSProfileSteper,
  resetDMSProfile,
  handleAccessBulkRoleUnassign,
  handleAccessBulkStoreUnassign,
  setSearchDetails
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
