/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useIntl } from 'react-intl';
import {
  Dialog,
  BUTTON_VARIANTS,
  SimpleTable,
  SimpleTableBody,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData
} from 'cdk-radial';
import { messages as commonMessages } from 'commonMessages';
import { DepartmentDialogProps } from './types';
import { messages } from './messages';

export const DepartmentDialog = ({
  handleClose,
  isOpen,
  count,
  tableData
}: DepartmentDialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <div data-testid="department-dialogBox-page">
      <Dialog
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleClose,
            text: intl.formatMessage(commonMessages.close),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        dataTestId="department"
        id="dialog-2"
        isOpen={isOpen}
        onClose={handleClose}
        title={intl.formatMessage(messages.departmentLength, {
          length: count
        })}
      >
        <SimpleTable aria-label="Table">
          <SimpleTableHead>
            <SimpleTableRow>
              <SimpleTableHeader textAlign="left" colSpan="1">
                {intl.formatMessage(messages.departmentId)}
              </SimpleTableHeader>
              <SimpleTableHeader textAlign="left" colSpan="1">
                {intl.formatMessage(messages.departmentLogo)}
              </SimpleTableHeader>
              <SimpleTableHeader textAlign="left" colSpan="1">
                {intl.formatMessage(messages.departmentType)}
              </SimpleTableHeader>
            </SimpleTableRow>
          </SimpleTableHead>
          <SimpleTableBody>
            {tableData.map((row: any) => (
              <SimpleTableRow key={row.id}>
                <SimpleTableData textAlign="left">{row.id}</SimpleTableData>
                <SimpleTableData textAlign="left">{row.name}</SimpleTableData>
                <SimpleTableData textAlign="left">{row.type}</SimpleTableData>
              </SimpleTableRow>
            ))}
          </SimpleTableBody>
        </SimpleTable>
      </Dialog>
    </div>
  );
};

export default DepartmentDialog;
