import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ipAddressDialog: {
    id: 'COMMON.DIALOGBOX.CONTENT.IPADDRESS',
    defaultMessage: 'Drive IP Address'
  },
  dmsCNumber: {
    id: 'COMMON.DIALOGBOX.INPUT.TEXTNAME.DMSNUMBER',
    defaultMessage: 'DMS CNumber'
  },
  cNumberIdentifier: {
    id: 'COMMON.DIALOGBOX.INDENTIFIER_CNUMBER',
    defaultMessage: 'Alternate Identifier Name: CNumber'
  },
  editDMSinfo: {
    id: 'COMMON.DIALOGBOX.EDIT_DMSINFO',
    defaultMessage: 'Edit DMS Information'
  },
  addDMSinfo: {
    id: 'COMMON.DIALOGBOX.ADD_DMSINFO',
    defaultMessage: 'Add DMS Information'
  }
});
