import { useIntl } from 'react-intl';
import { Dialog, BUTTON_VARIANTS } from 'cdk-radial';
import { messages as commonMessages } from 'commonMessages';
import { StoreMFADialogProps } from './types';
import { messages } from './messages';

export const StoreMFADialog = ({
  handleClose,
  handleSave,
  isOpen,
  isEnable
}: StoreMFADialogProps): JSX.Element => {
  const intl = useIntl();
  return (
    <div data-testid="storemfa-dialogbox-page">
      <Dialog
        dataTestId="mfa-enable"
        style={{ width: '24.21875vw' }}
        id="dialog-2"
        isOpen={isOpen}
        onClose={() => handleClose(false)}
        title={intl.formatMessage(messages.enableDisableDialog, {
          statustext: isEnable ? 'Enable' : 'Disable'
        })}
        disableOverlayClicked={false}
        buttonsProps={[
          {
            dataTestId: 'mfa-cancel',
            id: 'dialog-2-action-1',
            onClick: () => handleClose(false),
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: () => handleSave(true),
            text: intl.formatMessage(commonMessages.ok),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
      >
        <p>
          {intl.formatMessage(messages.StoreEnableMFADialog, {
            status: isEnable ? 'enable' : 'disable'
          })}
        </p>
      </Dialog>
    </div>
  );
};

export default StoreMFADialog;
