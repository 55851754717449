/* eslint-disable  @typescript-eslint/no-unsafe-return */
/* eslint-disable  @typescript-eslint/no-unsafe-call */
/* eslint-disable  @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/destructuring-assignment */
import { useIntl } from 'react-intl';
import { STATUS_INDICATOR_VARIANTS, StatusIndicator } from 'cdk-radial';
import routeIdentifiers from 'fixtures/routeIdentifiers';
import useEnterpriseId from 'hooks/useEnterpriseId';
import { Link } from 'react-router-dom';
import { messages } from 'components/common/StoresTransform/messages';
import { TransformStoreDetails } from 'components/pages/Stores/types/types';
import { StatusInLower } from 'components/common/StoresTransform/types';
import ExpandableList from 'components/common/ExpandableList/ExpandableList';
import { useGetStoreVisibilityStatusMappings } from 'fixtures/grid/stores/columnDefinitions';

export const TransformStoreId = (
  storeDetails: TransformStoreDetails
): JSX.Element => {
  const enterpriseId = useEnterpriseId();
  const enterprisePath = `enterprise/${enterpriseId}`;
  const storeId = storeDetails.data.storeId || storeDetails.data.id;
  return (
    <Link
      style={{ color: '#006cfa', textDecoration: 'none' }}
      to={`/${enterprisePath}/${
        routeIdentifiers.MANAGE_STORE_DETAILS.pathId.split('/:')[0]
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      }/${storeId}`}
    >
      {storeId}
    </Link>
  );
};

export const transformStoreLocation = (
  storeDetails: TransformStoreDetails
): string => {
  const { addresses } = storeDetails.data;
  const addressDetails = addresses?.find(
    (address: { addressType: string }) => address.addressType === 'Physical'
  );
  let addressString = '';
  if (
    addressDetails &&
    (addressDetails.streetAddress ||
      addressDetails.city ||
      addressDetails.stateCode ||
      addressDetails.postalCode)
  ) {
    addressString = `${addressDetails.streetAddress || ''}, ${
      addressDetails.city || ''
    }, ${addressDetails.stateCode || ''} ${addressDetails.postalCode || ''}`;
  }
  return addressString;
};

export const TransformDMSAccount = (
  storeDetails: TransformStoreDetails
): JSX.Element => {
  const departmentarr: string[] = storeDetails.data.departments?.map(
    (department: { name: any }) => department.name
  );

  const uniqueValues =
    departmentarr &&
    departmentarr?.filter(
      (item, index) => departmentarr.indexOf(item) === index
    );

  return <ExpandableList data={uniqueValues?.sort() || []} sliceBy={1} />;
};

export const TransformCMFNumber = (
  storeDetails: TransformStoreDetails
): JSX.Element => {
  const cmsFnumbers: string[] =
    storeDetails.data.externalSystemIdentifiers?.cmfNumber
      ?.filter((cmfNum: { status: string }) => cmfNum.status === 'ACTIVE')
      ?.map((cmfNum: { classification: string; cmfNumber: string }) => {
        if (cmfNum?.classification) {
          return `${cmfNum.cmfNumber} (${cmfNum.classification})`;
        }
        return cmfNum.cmfNumber;
      });

  const uniqueValues =
    cmsFnumbers &&
    cmsFnumbers?.filter((item, index) => cmsFnumbers.indexOf(item) === index);

  return <ExpandableList data={uniqueValues?.sort() || []} sliceBy={1} />;
};

export const TransformStoreVisibility = (
  storeDetails: TransformStoreDetails
): JSX.Element => {
  const storeVisibiltyMappings = useGetStoreVisibilityStatusMappings();
  const storeVisibility = storeDetails.data.isVisible ? 'yes' : 'no';
  const { label, variant } = storeVisibiltyMappings[storeVisibility] ?? {};
  return (
    <StatusIndicator
      id={`visibility-${storeDetails.data.id}`}
      data-testId="store-visibility-indicator"
      label={label}
      variant={variant}
      showIcon
    />
  );
};

export const TransformStatus = (
  storeDetails: TransformStoreDetails
): JSX.Element => {
  const intl = useIntl();
  const statusMappings = {
    open: {
      label: intl.formatMessage(messages.storeStatusOpen),
      variant: STATUS_INDICATOR_VARIANTS.POSITIVE
    },
    'pre-open': {
      label: intl.formatMessage(messages.storeStatusPreOpen),
      variant: STATUS_INDICATOR_VARIANTS.INFORMATION
    },
    sold: {
      label: intl.formatMessage(messages.storeStatusSold),
      variant: STATUS_INDICATOR_VARIANTS.DEFAULT
    },
    closed: {
      label: intl.formatMessage(messages.storeStatusClosed),
      variant: STATUS_INDICATOR_VARIANTS.NEGATIVE
    },
    inactive: {
      label: intl.formatMessage(messages.storeStatusInactive),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    duplicate: {
      label: intl.formatMessage(messages.storeStatusDuplicate),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    out_of_business: {
      label: intl.formatMessage(messages.storeStatusOutOfBusiness),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    },
    archived: {
      label: intl.formatMessage(messages.departmentStatusArchived),
      variant: STATUS_INDICATOR_VARIANTS.WARNING
    }
  };

  let label: string | undefined;
  let variant: string | undefined;
  const statusToLower =
    storeDetails.data.status.toLocaleLowerCase() as StatusInLower;

  if (statusToLower && statusMappings[statusToLower]) {
    ({ label, variant } = statusMappings[statusToLower]);
  }

  if (!label || !variant) {
    return (
      <StatusIndicator
        label=""
        variant={STATUS_INDICATOR_VARIANTS.DEFAULT}
        showIcon={false}
      />
    );
  }

  return (
    <StatusIndicator
      data-testId="status-indicator"
      label={label}
      variant={variant}
      showIcon
    />
  );
};
