import styled from 'styled-components';

const StyledInputBoxs = styled.div`
  padding-bottom: 15px;
  .alignButtonText {
    display: flex;
    align-items: center;
  }
`;

export default StyledInputBoxs;
