/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call,
@typescript-eslint/no-floating-promises */
import {
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { RootState } from 'redux/store';
import { setPrimaryStore } from 'redux/slices/storeDepartmentSlice';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import useColumns from '../../../../fixtures/grid/groups/groupsColumnDefinitions';
import { CommonTabProps, RowHeightType, StoreType } from './type';
import { ENTITY_NAME } from './constants';

export const getRowNodeId = (row: Record<string, string>): string => row.id;

const CommonTab = ({
  storeData,
  isLoading,
  tab,
  pushSelectedData
}: CommonTabProps): JSX.Element => {
  const columns = useColumns(tab);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const { primaryStore } = useSelector(
    (state: RootState) => state.slices.unassignDepartmentState
  );

  const sortStoreDetails = (storeDetails: StoreType[]) =>
    storeDetails.sort((currentGroup, nextGroup) => {
      if (currentGroup.type === 'Store' && nextGroup.type !== 'Store') {
        return -1;
      }
      if (currentGroup.type !== 'Store' && nextGroup.type === 'Store') {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    if (gridApi && storeData) {
      const modifiedStore = storeData.map(
        (data: { id: string; type: string }) => {
          if (data.type === 'Store') {
            return { ...data, primaryStore: primaryStore === data.id };
          }
          return { ...data };
        }
      );
      const sortedStoreData = sortStoreDetails(modifiedStore);
      gridApi.setRowData(sortedStoreData);
      gridApi.refreshCells({ force: true });
    }
  }, [storeData, isLoading, tab, gridApi, primaryStore]);

  const minHeight = 42;
  const getRowHeight = useCallback(
    (params: RowHeightType) =>
      params.data.rowHeight > minHeight ? params.data.rowHeight : minHeight,
    []
  );

  const onRowChecked = (event: SelectionChangedEvent) => {
    pushSelectedData(event.api.getSelectedRows(), tab);
  };

  const onSelectionChanged = (storeId: string) => {
    dispatch(setPrimaryStore(storeId));
  };

  return (
    <div
      className="container-full-height"
      ref={containerRef}
      data-testid="viewgroups-table"
    >
      <TableContainer showBorder>
        {tab !== ENTITY_NAME.VIEW_GROUP && (
          <FilterHeader
            filterSchema={[]} // removing filters until RDS resolves z-index issue on a dialog
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterSelections={[]} // removing filters until RDS resolves z-index issue on a dialog
            setFilterSelections={() => {}}
          />
        )}

        <TableContent
          gridApi={gridApi}
          searchValue={searchValue}
          filterSchema={[]}
          filterSelections={[]}
          tableHeight={300}
          setGridApi={setGridApi}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          setTotalRows={setTotalRows}
          loading={isLoading}
          columns={columns}
          getRowNodeId={getRowNodeId}
          context={{ onSelectionChanged }}
          immutableData
          rowHeight={50}
          onSelectionChanged={onRowChecked}
          getRowHeight={getRowHeight}
        />
        <TablePaging
          gridApi={gridApi}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default CommonTab;
