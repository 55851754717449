import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { BUTTON_VARIANTS, Dialog, Row, Column, Input } from 'cdk-radial';
import { Loading } from 'components/organisms/common';
import { saveExternalIdentifierData } from 'helpers/dialogBoxHelper';
import { DriveModalSelectBox } from 'components/molecules/common/Inputs';
import { messages as commonMessages } from 'commonMessages';
import { messages as textboxMessages } from '../../../molecules/common/Inputs/messages';
import { messages } from './messages';
import { AddExternalIdentifiersProps, Options } from './types';
import StyledInputBoxs from './styled';

export const AddExternalIdentifiersDialog = ({
  isOpen,
  handleClose,
  handleSave,
  type,
  tableData,
  filteroptionsdata
}: AddExternalIdentifiersProps): JSX.Element => {
  const intl = useIntl();
  const [selectType, setSelectType] = useState<string>('');
  const [selectIdentifierOptions, setSelectIdentifierOptions] = useState<any>(
    []
  );
  const [ipaddress, setIpaddress] = useState<string>('');
  const [identfierStatus, setIndentfierStatus] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<any>('');

  useEffect(() => {
    setSelectType(type);
    setSelectedName('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filteroptionsdata]);

  useEffect(() => {
    if (tableData.length > 0) {
      const dmsStatus: any =
        tableData[0] && tableData[0].isContainerFetchSuccess;
      const dmsoptions: Record<string, unknown>[] = [];
      if (dmsStatus) {
        const dmsInfor: any = tableData[0].dmsInfo;
        Array.from(dmsInfor).forEach((child: any) => {
          dmsoptions.push({ label: child.cnumber, value: child.ipAddress });
        });
        setSelectIdentifierOptions(dmsoptions);
      }
      setIndentfierStatus(dmsStatus);
      const dmsStateValue: any = tableData[0] && tableData[0]?.tableData;
      const optionname: any = {
        label:
          dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId,
        value: dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId
      };
      setSelectedName(optionname);

      setIpaddress(
        dmsStateValue && dmsStateValue?.driveIpAddress?.[0]?.externalSystemId
      );
    }
  }, [tableData]);

  const onSelectIdentifierOption = (options: Options[]) => {
    const optionname: any = options[0];
    setSelectedName(optionname);
    setIpaddress(optionname.value);
  };

  const handleModalClose = () => {
    setSelectedName('');
    setIpaddress('');
    setIndentfierStatus(false);
    handleClose(false);
  };

  const handleSaveExternalData = () => {
    saveExternalIdentifierData(tableData, selectedName, ipaddress, handleSave);
  };

  return (
    <div data-testid="addExternalIdentifiers-dialogBox-page">
      <Dialog
        buttonsProps={[
          {
            dataTestId: 'cancelExtIdentifiers',
            id: 'dialog-2-action-1',
            onClick: handleModalClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: selectedName.value && handleSaveExternalData,
            text: intl.formatMessage(commonMessages.save),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        hideCloseButton
        id="dialog-2"
        isOpen={isOpen}
        onClose={handleClose}
        title={
          selectType === 'addExternal'
            ? intl.formatMessage(messages.addDMSinfo)
            : intl.formatMessage(messages.editDMSinfo)
        }
        disableOverlayClicked={false}
        dataTestId="addExtIdentifiers"
      >
        {!identfierStatus ? (
          <Loading />
        ) : (
          <>
            {selectType !== 'addExternal' && (
              <Row>
                <span className="identifierName">
                  {intl.formatMessage(messages.cNumberIdentifier)}
                </span>
              </Row>
            )}

            <Row className="dmsStyle">
              <Column>
                <DriveModalSelectBox
                  uid={1}
                  name={intl.formatMessage(messages.dmsCNumber)}
                  isPortalActive={isOpen}
                  options={selectIdentifierOptions || []}
                  selectValue={selectedName || ''}
                  onChangeAddValue={(OptionItems: Options[]) =>
                    onSelectIdentifierOption(OptionItems)
                  }
                />
              </Column>
              <Column>
                <Row>
                  <StyledInputBoxs>
                    <Input
                      id="2"
                      label={intl.formatMessage(messages.ipAddressDialog)}
                      name={intl.formatMessage(messages.ipAddressDialog)}
                      onChange={() => ({})}
                      placeholder={`Enter ${intl.formatMessage(
                        messages.ipAddressDialog
                      )}`}
                      value={ipaddress || ''}
                      isDisabled
                      data-testid="drive-input-box"
                      enableCustomValidation
                      hasError={false}
                      isRequired={false}
                      errorMessage={
                        intl.formatMessage(messages.ipAddressDialog) ===
                        'Domain'
                          ? intl.formatMessage(
                              textboxMessages.driveTextBoxError
                            )
                          : intl.formatMessage(
                              textboxMessages.driveTextBoxRequiredField
                            )
                      }
                    />
                  </StyledInputBoxs>
                </Row>
              </Column>
            </Row>
          </>
        )}
      </Dialog>
    </div>
  );
};
