/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-props-no-spreading */
import { useIntl } from 'react-intl';
import React, { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useFeatureFlags } from '@harnessio/ff-react-client-sdk';
import {
  Column,
  Grid,
  Row,
  Divider,
  MenuWrapper,
  NavigationContainer,
  NavigationMenu,
  NavigationSection,
  IconArrowBack,
  ListItem,
  ListItemGraphic,
  ListItemPrimaryText,
  ListItemText,
  TOOLTIP_HORIZONTAL_ALIGNMENTS,
  TOOLTIP_VERTICAL_ALIGNMENTS,
  withTooltip,
  IconButton,
  IconAccountCircle,
  THEMES,
  HeaderContextMenuContent,
  TOAST_VARIANTS
} from 'cdk-radial';
import { useDispatch, useSelector } from 'react-redux';
import { AMP_EVENT_TYPES } from 'constants/constants';
import getPermissions from 'helpers/getStorePermissions';
import { useAnalytics } from 'dms-analytics';
import { ApiError } from 'redux/services/admin-service/types';
import { usePostChangePasswordMutation } from 'redux/services/identity-service';
import routeIdentifiers, {
  pathToPageId,
  pageIdToPath,
  isCDKUserPath
} from 'fixtures/routeIdentifiers';
import {
  useGetUserAppsQuery,
  useRevokeImpersonationMutation
} from 'redux/services/authz-service';
import {
  isCDKHome,
  isEnterprise,
  isStore,
  isUserDetails
} from 'helpers/sideNavHelper';
import LocaleToggle from 'components/common/LocaleToggle';
import { ResetPassword } from 'components/molecules/common/Header/ResetPassword/ResetPassword';
import { ChangePasswordPayload } from 'components/molecules/common/Header/ResetPassword/type';
import ErrorToast from 'components/ui/ErrorToast';
import AppSwitcher from 'components/molecules/common/Header/AppSwitcher/AppSwitcher';
import { useClient } from 'providers/ClientProvider';
import { RootState } from 'redux/store';
import { WarningDialog } from 'components/common/Dialogs/WarningDialog';
import { setGlobalToast } from 'redux/slices/commonSlice';
import useEnterpriseId from 'hooks/useEnterpriseId';
import { messages as impersonationMessages } from 'components/molecules/common/Header/RevokeImpersonationDialog/messages';
import { messages as commonMessages } from 'commonMessages';
import AvatarText from '../../../molecules/common/SideNav/AvatarText/AvatarText';
import Description from '../../../molecules/common/SideNav/Description/Description';
import Footer from '../../../molecules/common/SideNav/Footer/Footer';
import {
  StyledAppNavigationMenuContainer,
  StyledAppSwitch,
  StyledAppSwitchWrapper,
  StyledSideNav,
  StyledSideNavItemsContainer,
  StyledSignoutDialog
} from './styled';
import { getFullName, hasLoggedInAsCDKUser, isCDKUser } from './utils';
import { messages } from './messages';
import EnterpriseAdmin from './EnterpriseAdmin';
import ImpersonatationDetails from './Components/ImpersonatationDetails';
import OldNewCAMSwitcher from './Components/OldNewCAMSwitcher';
import { HelpButtonWithToolTip } from './Components/TooltipHelpButton';
import {
  useCdkUserMenuItems,
  useDealerMenuItems,
  useProfileMenuItem
} from './hooks';
import { DEFAULT_SECTION, LOG_OUT_URL } from './constants';

const SideNav = (): JSX.Element => {
  const { pathname } = useLocation();
  const pathEnterpriseId = useEnterpriseId();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const profilePopupRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useAnalytics();
  const { oktaAuth, authState } = useOktaAuth();
  const {
    uienablegroupmenu,
    uienablelookerreport,
    uicontrolcommonadmintoggle
  } = useFeatureFlags();

  const [impersonateRefresh, setImpersonateRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeMenuItemId, setActiveMenuItemId] = useState(
    pathToPageId[pathname]
  );
  const [expandedMenuItemIds, setExpandedMenuItemIds] =
    useState(DEFAULT_SECTION);

  const [postChangePassword] = usePostChangePasswordMutation();

  const client = useClient();
  const { userInfo, enterpriseBasicDetails } = client;
  const userData = userInfo;
  const permissionsData = client?.permissions;
  const userGuid = userData?.fullUser?.user?.userGuid || '';
  const { isEnterprisePermission } = getPermissions(permissionsData);
  const fullName = getFullName(userData);
  const {
    commonState: { isImpersonationActiveForCurrentEnterprise }
  } = useSelector((state: RootState) => state.slices);

  const userAppsQuerySkip = !userGuid || !userInfo?.fullUser?.enterprise?.id;

  const validEnterpriseId =
    pathEnterpriseId || userInfo?.fullUser?.enterprise?.id;

  const {
    data: appData,
    isError: isGetUserAppsError,
    error: getUserAppsError
  } = useGetUserAppsQuery(
    {
      userGUID: userGuid,
      enterpriseId: validEnterpriseId
    },
    {
      skip: userAppsQuerySkip
    }
  );

  const [
    revokeImpersonation,
    { isError: isRevokeImpersonationError, error: impersonationError }
  ] = useRevokeImpersonationMutation();

  const handleTrackEvent = (event: string) => {
    if (!hasLoggedInAsCDKUser(authState)) {
      trackEvent(event);
    }
  };

  const routeToDir = (
    route: string,
    eventType: string | undefined = undefined
  ) => {
    setActiveMenuItemId(route);
    if (eventType) {
      handleTrackEvent(eventType);
    }
    history.push(pageIdToPath[route]);
  };

  const routeToPath = (
    route: string,
    eventType: string | undefined = undefined
  ) => {
    setActiveMenuItemId(route);
    if (eventType) {
      handleTrackEvent(eventType);
    }
    history.push(route);
  };

  const commonRoutePath = `/enterprise/${pathEnterpriseId}`;

  const handleGeneralMenu = () => {
    setActiveMenuItemId(routeIdentifiers.ENTERPRISE_GENERAL.pathId);
    history.push(`${commonRoutePath}`);
  };

  const handleUsers = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setExpandedMenuItemIds({
        ...expandedMenuItemIds,
        'users-section': true
      });
    }
  };

  const handleUser = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.USERS.pathId}`,
      AMP_EVENT_TYPES.SELECT_ALL_USERS
    );

  const handleManageUserImport = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.MANAGE_USER_IMPORTS.pathId}`
    );

  const handleMergeUser = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.MERGE_USER.pathId}`,
      AMP_EVENT_TYPES.SELECT_ALL_USERS
    );

  const handleEmailNotification = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.EMAIL_NOTIFICATIONS.pathId}`,
      AMP_EVENT_TYPES.SELECT_EMAIL_NOTIFICATIONS
    );

  const handleRoles = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.ROLES.pathId}`,
      AMP_EVENT_TYPES.SELECT_ALL_ROLES
    );

  const handleAllRoles = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setExpandedMenuItemIds({
        ...expandedMenuItemIds,
        'roles-section': true
      });
    }
  };

  const handleStores = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.STORES.pathId}`,
      AMP_EVENT_TYPES.SELECT_STORES
    );

  const handleReports = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.REPORTS_HOME.pathId}`,
      AMP_EVENT_TYPES.SELECT_LOGS_AND_REPORTS
    );

  const handleSetting = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.SETTINGS_HOME.pathId}`,
      AMP_EVENT_TYPES.SELECT_SETTINGS
    );

  const handleGroup = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.GROUPS.pathId}`,
      AMP_EVENT_TYPES.SELECT_GROUPS
    );

  const handleLookerReport = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.USER_MANAGEMENT_REPORTS_HOME.pathId}`,
      AMP_EVENT_TYPES.SELECT_LOGS_AND_REPORTS
    );

  const handleBulkUnAssignment = () =>
    routeToPath(
      `${commonRoutePath}/${routeIdentifiers.BULK_UNASSIGN_USERS.pathId}`
    );

  const {
    GENERAL,
    USERS,
    ROLES,
    STORES,
    REPORTS,
    SETTINGS,
    GROUPS,
    LOOKER_REPORT,
    BULK_UN_ASSIGNMENT
  } = useDealerMenuItems({
    handleGeneralMenu,
    handleUsers,
    handleUser,
    handleManageUserImport,
    handleMergeUser,
    handleEmailNotification,
    handleRoles,
    handleAllRoles,
    handleStores,
    handleReports,
    handleSetting,
    handleGroup,
    handleLookerReport,
    handleBulkUnAssignment
  });

  const dealerMenuItems = [GENERAL, USERS, ROLES, STORES, REPORTS, SETTINGS];
  const cdkUserMenuItems = useCdkUserMenuItems(routeToDir);

  const isCDKUserView = isCDKUser(userData) && !!isEnterprisePermission;
  const isCdkUserPageId = isCDKUserPath(pathname, pathEnterpriseId);
  const isHomePage = isCDKUserView && isCdkUserPageId;
  const isNavigating = pathname === '/';

  const handleExpandCollapse = (isCollapsedValue: boolean) => {
    setIsCollapsed(isCollapsedValue);
  };

  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [changePath, setChangePath] = useState<string>('/');
  const [canExit, setCanExit] = useState<boolean>(false);

  const handleBlockNavigation = history.block((location): any => {
    setChangePath(location.pathname);
    if (
      isImpersonationActiveForCurrentEnterprise &&
      location.pathname === '/cdk_home'
    ) {
      setShowWarningDialog(!showWarningDialog);
      return false;
    }
    return true;
  });

  if (!isCDKUserView && history.location.pathname === '/cdk_home') {
    history.replace('/');
  }

  const handleCloseWarningDialog = () => {
    setShowWarningDialog(false);
  };

  const handleEndImpersonationWarningDialog = async () => {
    setShowWarningDialog(false);
    const resp = await revokeImpersonation(userGuid);
    if (!('error' in resp)) {
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.disableImpersonation, {
            enterprise: pathEnterpriseId || ''
          }),
          variant: TOAST_VARIANTS.POSITIVE
        })
      );
      setImpersonateRefresh(true);
      setCanExit(true);
      history.push('/cdk_home');
    }
  };

  useEffect(() => {
    handleBlockNavigation();
    if (canExit && changePath) {
      history.push(changePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canExit, changePath]);

  useEffect(() => {
    if (isStore(pathname)) {
      setActiveMenuItemId(routeIdentifiers.STORES.pageId);
    } else if (isUserDetails(pathname)) {
      setActiveMenuItemId(routeIdentifiers.USERS.pageId);
    } else if (isCDKHome(pathname)) {
      setActiveMenuItemId(routeIdentifiers.CDK_HOME.pageId);
    } else if (isEnterprise(pathname)) {
      setActiveMenuItemId(routeIdentifiers.ENTERPRISE_GENERAL.pageId);
    }
  }, [pathname]);

  useEffect(() => {
    client?.refetchAuthenticationQuery();
    client?.refetchPermissionsQuery();
  }, [impersonateRefresh, client]);

  const getExpandedListItemsCount = (items: Record<string, boolean>) =>
    Object.values(items).reduce(
      (count, value) => (value ? count + 1 : count),
      0
    );

  const handleMenuItemsExpandCollapse = (itemIds: any) => {
    const shouldItemExpandCollapse =
      !isCollapsed ||
      getExpandedListItemsCount(itemIds) >
        getExpandedListItemsCount(expandedMenuItemIds);

    if (shouldItemExpandCollapse) {
      setExpandedMenuItemIds(itemIds);
    }
  };

  const getNavItems = (isHome: boolean) => {
    const {
      hasGroupPermission,
      isServiceUsersPermission,
      hasBulkUnassignUsersPermission,
      isUserListDownload
    } = getPermissions(permissionsData);
    const canShowGroup =
      uienablegroupmenu || (isCDKUser(userData) && hasGroupPermission);

    if (canShowGroup) {
      dealerMenuItems.splice(4, 0, GROUPS);
    }

    if (uienablelookerreport && isUserListDownload && !isCDKUser(userData)) {
      const reportIndex = dealerMenuItems.length - 2;
      dealerMenuItems.splice(reportIndex, 0, LOOKER_REPORT);
    }

    if (!isServiceUsersPermission) {
      cdkUserMenuItems.splice(2, 1);
    }

    if (hasBulkUnassignUsersPermission) {
      const userMenuList = dealerMenuItems.find(
        menuItem => menuItem.id === 'users-section'
      );
      userMenuList?.items?.push(BULK_UN_ASSIGNMENT);
    }
    const items = isHome ? cdkUserMenuItems : dealerMenuItems;
    return items;
  };

  // const isEnterpriseIdNotSet = pathEnterpriseId;
  const canShowEnterpriseAdmin = !(
    isHomePage || hasLoggedInAsCDKUser(authState)
  );

  const ProfileToolTipProps = {
    id: 'tooltip-id-1',
    text: intl.formatMessage(messages.profile),
    theme: THEMES.DARK,
    delay: 100
  };

  const handleAccount = (openValue: boolean) => {
    setIsOpen(openValue);
    if (openValue) {
      profilePopupRef.current?.focus();
    } else {
      profilePopupRef.current?.blur();
    }
  };

  const TooltipProfileButton = (props: any) => (
    <IconButton
      backgroundAppearance="light"
      icon={
        <IconAccountCircle
          focusable={false}
          height={50}
          role="presentation"
          viewBox="0 0 24 24"
          width={50}
        />
      }
      iconColor="gray"
      iconSize={24}
      onClick={() => handleAccount(!isOpen)}
      data-testid="Top-header-account-button"
      text="Account Button"
      {...props}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const ButtonProfileWrap = withTooltip(
    TooltipProfileButton,
    ProfileToolTipProps
  );

  const displayResetPassword = () => {
    setPasswordError('');
    setShowResetPasswordDialog(!showResetPasswordDialog);
  };

  const getOktaUserId = (): string => {
    const userId = (authState?.accessToken?.claims.uid as string) || '';
    return userId;
  };

  const handleRessetPassword = async (payload: ChangePasswordPayload) => {
    const userId = getOktaUserId();
    const response = await postChangePassword({ userId, body: payload });

    const apiError = response as ApiError;
    if (apiError.error) {
      setPasswordError(apiError?.error?.data?.message);
    } else {
      setShowResetPasswordDialog(!showResetPasswordDialog);
    }
  };

  const handleResetPassword = () => {
    setShowResetPasswordDialog(true);
  };

  const PROFILE_MENU_LIST_ITEMS = useProfileMenuItem(handleResetPassword);

  const onSignOut = async () => {
    const token = oktaAuth.getIdToken();
    if (token) {
      try {
        localStorage.clear();
        await oktaAuth.signOut({
          postLogoutRedirectUri: LOG_OUT_URL,
          clearTokensBeforeRedirect: true
        });
      } catch (error) {
        window.location.assign(LOG_OUT_URL);
      }
    } else {
      window.location.assign(LOG_OUT_URL || '');
    }
  };

  const canShowAllEnterpriseBackButton = isCDKUserView && !isHomePage;
  const canShowGridContainer = !isHomePage;
  const canShowAppSwitcher = !isCollapsed && isCDKUserView && isHomePage;
  const canShowSideNav = permissionsData && !isNavigating;
  const avatarStyle = !isCollapsed
    ? 'rounded-row'
    : 'collapse-enterprise-container';

  const showOldCommonAdminToggle =
    isCDKUser(userData) || uicontrolcommonadmintoggle;

  return (
    <StyledSideNav data-testid="side-nav">
      {isGetUserAppsError && <ErrorToast dataError={getUserAppsError} />}
      {isRevokeImpersonationError && (
        <ErrorToast dataError={impersonationError} />
      )}
      <NavigationSection>
        <NavigationContainer
          isCollapsed={isCollapsed}
          isPositionStatic
          className="nav-container"
        >
          {canShowSideNav && (
            <>
              {canShowAllEnterpriseBackButton && (
                <ListItem
                  className="cdk-home-row"
                  onClick={() => routeToDir(routeIdentifiers.CDK_HOME.pageId)}
                >
                  <ListItemGraphic className="cdk-home-iconArrowBack">
                    <IconArrowBack />
                  </ListItemGraphic>
                  <ListItemText>
                    <ListItemPrimaryText className="cdk-home-column-text">
                      {intl.formatMessage(messages.sideNavAllEnterprises)}
                    </ListItemPrimaryText>
                  </ListItemText>
                </ListItem>
              )}
              {canShowGridContainer && (
                <Grid className="grid-container">
                  {!isCollapsed && (
                    <>
                      <StyledAppSwitch>
                        <AppSwitcher userApps={appData?.userApps || []} />
                      </StyledAppSwitch>
                      <Divider
                        className="nav-section-divider"
                        data-testid="divider"
                      />
                    </>
                  )}
                  <div className={avatarStyle}>
                    <Row className="rounded-row-box">
                      <Column
                        className="avatar-container-column"
                        data-testid="avatar-text-container-column"
                      >
                        <AvatarText
                          label={enterpriseBasicDetails?.name[0] || ''}
                        />
                      </Column>
                      {!isCollapsed && (
                        <Column
                          className="desc-container-column"
                          data-testid="desc-column"
                        >
                          <Grid data-testid="column-grid">
                            <Description
                              labelHeading={enterpriseBasicDetails?.id || ''}
                              labelDescription={
                                enterpriseBasicDetails?.name || ''
                              }
                            />
                          </Grid>
                        </Column>
                      )}
                    </Row>
                    {!isCollapsed && (
                      <ImpersonatationDetails
                        impersonateRefresh={impersonateRefresh}
                        setImpersonateRefresh={setImpersonateRefresh}
                      />
                    )}
                  </div>
                </Grid>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100% - 20px)',
                  justifyContent: 'space-between'
                }}
              >
                <MenuWrapper data-testid="navigation-menu">
                  {canShowAppSwitcher && (
                    <StyledAppSwitchWrapper>
                      <AppSwitcher userApps={appData?.userApps || []} />
                    </StyledAppSwitchWrapper>
                  )}
                  {!isCollapsed && showOldCommonAdminToggle && (
                    <>
                      <OldNewCAMSwitcher userEnterpriseId={validEnterpriseId} />
                      <Divider
                        className="nav-section-divider"
                        data-testid="divider"
                      />
                    </>
                  )}
                  <StyledAppNavigationMenuContainer>
                    <NavigationMenu
                      dataTestId="navigation-menu"
                      activeItemId={activeMenuItemId}
                      expandedItemIds={expandedMenuItemIds}
                      isCollapsed={isCollapsed}
                      items={getNavItems(isHomePage)}
                      onItemsExpandCollapse={handleMenuItemsExpandCollapse}
                    />
                  </StyledAppNavigationMenuContainer>
                </MenuWrapper>
              </div>
              {canShowEnterpriseAdmin && (
                <EnterpriseAdmin isCollapsed={isCollapsed} />
              )}
              <StyledSideNavItemsContainer isCollapsed={isCollapsed}>
                <HelpButtonWithToolTip
                  horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE}
                  marginAroundElement={8}
                  verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
                />
                <ButtonProfileWrap
                  horizontalAlignment={TOOLTIP_HORIZONTAL_ALIGNMENTS.MIDDLE}
                  marginAroundElement={8}
                  verticalAlignment={TOOLTIP_VERTICAL_ALIGNMENTS.TOP}
                />
                <LocaleToggle />
              </StyledSideNavItemsContainer>
              <StyledSignoutDialog
                ref={profilePopupRef}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                onBlur={event => {
                  if (!event.currentTarget.contains(event.relatedTarget)) {
                    handleAccount(false);
                  }
                }}
              >
                {isOpen && (
                  <HeaderContextMenuContent
                    data-testid="Top-header-account-popup"
                    avatarProps={{ label: fullName }}
                    listItems={PROFILE_MENU_LIST_ITEMS}
                    usernameTitle={fullName}
                    className="accountPopup"
                    onSignOut={onSignOut}
                    signOutLabel={intl.formatMessage(messages.signOut)}
                    style={{ width: '240px', backgroundColor: 'grey' }}
                  />
                )}
              </StyledSignoutDialog>
              <Footer
                isCollapsed={isCollapsed}
                handleExpandCollapse={handleExpandCollapse}
                data-testid="footer"
              />
              {showResetPasswordDialog && (
                <ResetPassword
                  passwordError={passwordError}
                  handleClose={displayResetPassword}
                  handleCloseExit={displayResetPassword}
                  handleSaveExit={handleRessetPassword}
                />
              )}
            </>
          )}
          {showWarningDialog && (
            <WarningDialog
              title={intl.formatMessage(
                impersonationMessages.revokeImpersonationDialogTitle
              )}
              description={intl.formatMessage(
                impersonationMessages.confirmImpersonation
              )}
              handleClose={handleCloseWarningDialog}
              handleOk={handleEndImpersonationWarningDialog}
              primaryButtonText={intl.formatMessage(
                impersonationMessages.dialogRevokeButton
              )}
              secondaryButtonText={intl.formatMessage(commonMessages.cancel)}
            />
          )}
        </NavigationContainer>
      </NavigationSection>
    </StyledSideNav>
  );
};

export default SideNav;
