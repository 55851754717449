import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editIdentifier: {
    id: 'COMMON.DIALOGBOX.EDIT_INDENTIFER',
    defaultMessage: 'Edit Alternate Identifier'
  },
  selectedIdentifier: {
    id: 'COMMON.DIALOGBOX.INDENTIFER_SELECTED_NAME',
    defaultMessage: 'Alternate Identifier Name: {title}'
  },
  identifierErrorMessage: {
    id: 'COMMON.DIALOGBOX.IDENTIFIER_ERROR_MSG',
    defaultMessage: 'Value is required. Entry will be deleted if left blank.'
  }
});
