/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useEffect } from 'react';
import {
  Dialog,
  BUTTON_VARIANTS,
  TOAST_VARIANTS,
  TOAST_POSITIONS,
  Toast
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import { handleDownloadClick } from 'utils/utility';
import { Loading } from 'components/organisms/common';
import { messages as commonMessages } from 'commonMessages';
import { getEmptyTableMessage } from 'helpers/tableHelper';
import TableContainer from 'components/templates/TableLayout/TableContainer/TableContainer';
import FilterHeader from 'components/templates/TableLayout/FilterHeader/FilterHeader';
import TableContent from 'components/templates/TableLayout/TableContent/TableContent';
import TablePaging from 'components/templates/TableLayout/TablePaging/TablePaging';
import TableHeader from 'components/templates/TableLayout/TableHeader/TableHeader';
import { messages } from '../messages';
import { AgGridDialogBoxProps } from '../types';
import { useAccountsColumns } from '../columns';

export const getRowNodeId = (row: Record<string, string>): string => row.id;
export const getRowHeight = (): number => 70;

export const DMSAccountsDialogBox = ({
  isOpen,
  handleClose,
  handleSave,
  prevTableData,
  tableData,
  isTableDataLoading
}: AgGridDialogBoxProps): JSX.Element => {
  const intl = useIntl();

  const accountsColumns = useAccountsColumns();
  const accountsDefinitions = accountsColumns;

  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [modalData, setModalData] = useState<any>([]);
  const [savebutton, setSavebutton] = useState<boolean>(true);

  useEffect(() => {
    let data: any = [];

    if (prevTableData.length > 0) {
      const ids = prevTableData.map((o: any) => o.acct || o.id);
      const filtered = tableData.filter(({ id }: any) => !ids.includes(id));
      data = filtered;
      setModalData(filtered);
    } else {
      data = tableData;
      setModalData(tableData);
    }
    if (gridApi && data) {
      gridApi.setRowData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, prevTableData, gridApi]);

  const displayedRowCount: number =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;

  const onSelectionChanged = (event: any) => {
    const selectedData = event.api.getSelectedRows();
    setSelectedRows([...selectedData]);
    setSavebutton(selectedData.length === 0);
  };

  const handleCheckedData = () => {
    if (selectedRows.length > 0) {
      Array.from(selectedRows).forEach((a: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!a.hasOwnProperty('selectedValue')) {
          Object.assign(a, {
            selectedValue: ''
          });
        }
      });
      handleSave(selectedRows);
    }
  };

  const onChangeAccountData = (options: any, id: string) => {
    Array.from(modalData).forEach((a: any) => {
      if (a.id === id) {
        Object.assign(a, {
          selectedValue: options[0].value
        });
      }
    });
  };

  const toasterForEmptyTable = (tableType: string) => (
    <Toast
      position={TOAST_POSITIONS.INLINE}
      variant={TOAST_VARIANTS.NEUTRAL}
      content={intl.formatMessage(messages.NoMessageToast, {
        message: tableType
      })}
      id="toast-1"
    />
  );

  return (
    <Dialog
      dataTestId="dmsAccounts"
      style={{ width: '55vw', maxHeight: '90vw' }}
      disableOverlayClicked={false}
      id="dialog-2"
      isOpen={isOpen}
      onClose={() => handleClose()}
      title={intl.formatMessage(messages.assignDMSAccounts)}
      buttonsProps={[
        {
          id: 'dialog-2-action-1',
          onClick: handleClose,
          text: intl.formatMessage(commonMessages.cancel),
          variant: 'text'
        },
        {
          id: 'dialog-2-action-1',
          isDisabled: savebutton,
          onClick: handleCheckedData,
          text: intl.formatMessage(messages.assignButton),
          variant: BUTTON_VARIANTS.PRIMARY
        }
      ]}
    >
      {modalData.length === 0 && isTableDataLoading && (
        <Loading dataTestId="dialogBoxWithAGGrid-dmsAccounts-loader" />
      )}

      {modalData.length === 0 &&
        !isTableDataLoading &&
        toasterForEmptyTable('Job Account')}
      {modalData.length > 0 && (
        <TableContainer showBorder>
          <TableHeader
            isDownloadable
            disableDownloadButton={displayedRowCount === 0}
            onDownloadClick={(): void => handleDownloadClick(gridApi)}
            headerTitle={intl.formatMessage(messages.accountListHeader, {
              count: modalData.length || 0
            })}
          />
          <FilterHeader
            filterSchema={[]} // removing filters until RDS resolves z-index issue on a dialog
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterSelections={[]} // removing filters until RDS resolves z-index issue on a dialog
            setFilterSelections={() => {}}
          />

          <TableContent
            gridApi={gridApi}
            searchValue={searchValue}
            filterSchema={[]}
            filterSelections={[]}
            tableHeight={300}
            setGridApi={setGridApi}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
            setTotalRows={setTotalRows}
            loading={isTableDataLoading}
            columns={accountsDefinitions}
            context={{ onChangeAccountData }}
            rowHeight={200}
            getRowNodeId={getRowNodeId}
            getRowHeight={getRowHeight}
            onSelectionChanged={onSelectionChanged}
            data={modalData}
            overlayNoRowsTemplateMessage={getEmptyTableMessage(
              searchValue,
              intl
            )}
          />
          <TablePaging
            gridApi={gridApi}
            currentPage={currentPage}
            totalPages={totalPages}
            totalRows={totalRows}
          />
        </TableContainer>
      )}
    </Dialog>
  );
};
