import { BUTTON_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { StyledExitDialog } from './styled';
import { ExitDialogProps } from './type';

export const ExitDialog = ({
  description,
  handleSaveExit,
  handleCloseExit,
  handleClose
}: ExitDialogProps): JSX.Element => {
  const intl = useIntl();

  const BUTTON_PROPS = [
    {
      id: 'dialog-2-action-1',
      onClick: handleCloseExit,
      text: intl.formatMessage(messages.exitTitle),
      variant: 'text'
    },
    {
      id: 'dialog-2-action-1',
      onClick: handleSaveExit,
      text: intl.formatMessage(messages.saveAndExit),
      variant: BUTTON_VARIANTS.PRIMARY
    }
  ];

  const TITLE = intl.formatMessage(messages.exitTitle);

  return (
    <StyledExitDialog
      dataTestId="dialog-exit-save"
      disableOverlayClicked={false}
      id="dialog-exit-save"
      isOpen
      onClose={() => handleClose(false)}
      title={TITLE}
      buttonsProps={BUTTON_PROPS}
    >
      <p>
        {intl.formatMessage(messages.dialogSaveContent, { text: description })}
      </p>
    </StyledExitDialog>
  );
};
