/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { BUTTON_VARIANTS, TabPanel, Tab, Tabs } from 'cdk-radial';
import { useIntl } from 'react-intl';
import CommonTab from 'components/pages/Groups/Tabs/CommonTab';
import { ENTITY_NAME } from 'components/pages/Groups/Tabs/constants';
import { Entity } from 'components/pages/Groups/Tabs/type';
import { messages as commonMessages } from 'commonMessages';
import { messages } from './messages';
import { StyledDialog } from './styled';
import { DialogWithTabsProps } from './types';

export const DialogBoxWithTabs = ({
  isOpen,
  tableData,
  handleClose,
  handleSave
}: DialogWithTabsProps): JSX.Element => {
  const intl = useIntl();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [stores, setStores] = useState<Entity[]>([]);
  const [departments, setDepartments] = useState<Entity[]>([]);
  const [groups, setGroups] = useState<Entity[]>([]);

  const handleEntities = (selectedEntities: Entity[], tab: string) => {
    const entities = selectedEntities.map(entity => ({
      id: entity.id,
      name: entity.name,
      type: tab
    }));

    switch (tab) {
      case ENTITY_NAME.STORE:
        setStores(entities);
        break;
      case ENTITY_NAME.DEPARTMENT:
        setDepartments(entities);
        break;
      case ENTITY_NAME.GROUP:
        setGroups(entities);
        break;
      default:
        break;
    }
  };

  const handleAddEntities = () => {
    const newGroup = [...stores, ...departments, ...groups];
    handleSave(newGroup);
    setStores([]);
    setDepartments([]);
    setGroups([]);
  };

  return (
    <div data-testid="dialogBoxWithTab-page">
      <StyledDialog
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: handleAddEntities,
            text: intl.formatMessage(messages.addEntityButton),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        dataTestId="dialogwithtabs"
        id="dialog-1"
        isOpen={isOpen}
        onClose={handleClose}
        title={intl.formatMessage(messages.addEntityButton)}
      >
        <div className="tabsStylePostion">
          <Tabs
            data-testid="grouping-tabs-list"
            style={{ padding: '0rem !importent' }}
            activeItemIndex={activeItemIndex}
            items={[
              {
                id: 'tab-1',
                panelId: 'panel-1',
                renderPanel: (props: JSX.IntrinsicAttributes) => (
                  <TabPanel {...props}>
                    <CommonTab
                      storeData={tableData?.data}
                      isLoading={tableData?.isLoading}
                      tab={ENTITY_NAME.STORE}
                      pushSelectedData={handleEntities}
                    />
                  </TabPanel>
                ),
                renderTab: (props: JSX.IntrinsicAttributes) => (
                  <Tab
                    label={intl.formatMessage(messages.storesTabDialog)}
                    hideLabel={false}
                    {...props}
                  />
                )
              },
              {
                id: 'tab-2',
                panelId: 'panel-2',
                renderPanel: (props: JSX.IntrinsicAttributes) => (
                  <TabPanel {...props}>
                    <CommonTab
                      storeData={tableData?.departments}
                      isLoading={tableData?.isLoading}
                      tab={ENTITY_NAME.DEPARTMENT}
                      pushSelectedData={handleEntities}
                    />
                  </TabPanel>
                ),
                renderTab: (props: JSX.IntrinsicAttributes) => (
                  <Tab
                    label={intl.formatMessage(messages.departmentsTabTitle)}
                    hideLabel={false}
                    {...props}
                  />
                )
              },
              {
                id: 'tab-3',
                panelId: 'panel-3',
                renderPanel: (props: JSX.IntrinsicAttributes) => (
                  <TabPanel {...props}>
                    <CommonTab
                      storeData={tableData?.groupedData}
                      isLoading={tableData?.isLoading}
                      tab={ENTITY_NAME.GROUP}
                      pushSelectedData={handleEntities}
                    />
                  </TabPanel>
                ),
                renderTab: (props: JSX.IntrinsicAttributes) => (
                  <Tab
                    label={intl.formatMessage(messages.groupsTabTitle)}
                    hideLabel={false}
                    {...props}
                  />
                )
              }
            ]}
            onActiveItemChange={setActiveItemIndex}
            tabsLabel={intl.formatMessage(messages.groupTabsDescription)}
          />
        </div>
      </StyledDialog>
    </div>
  );
};
