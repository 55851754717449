/* eslint-disable react/require-default-props */
import { useRef, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  Menu,
  MenuListItem,
  Input,
  IconArrowDropUp,
  IconArrowDropDown,
  BaseInputField,
  Row
} from 'cdk-radial';
import { Z_INDEX } from 'constants/zindex';
import StyledInputBoxs from './styled';
import { messages } from './messages';

const StyledInput = styled(Input)`
  ${BaseInputField} {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  z-index: ${Z_INDEX.MENU_INDEX};
`;

const StyledMenuListItem = styled(MenuListItem)`
  z-index: ${Z_INDEX.MENU_INDEX};
`;

export type Options = {
  label: string | JSX.Element;
  value: string | any;
};

interface Props {
  uid: string | number;
  name: string;
  selectValue: any;
  disabled: boolean;
  options: Options[];
  onChangeAddValue: (options: any) => void;
  required?: boolean;
  errorText?: boolean;
  changeValueByBlur?: () => void;
}

const DriveSelectBox = ({
  uid,
  name,
  selectValue,
  disabled,
  options,
  onChangeAddValue,
  changeValueByBlur,
  required = false,
  errorText = false
}: Props): JSX.Element => {
  const intl = useIntl();
  const targetRef = useRef();
  const dimensionRef = useRef();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelect = (option: any) => {
    onChangeAddValue([option]);
  };

  return (
    <StyledInputBoxs data-testid="drive-selectbox">
      <Row>
        <div
          style={{ width: '100%' }}
          data-testid="single-select-dropdown"
          className="single-select-dropdown"
        >
          <StyledInput
            dataTestId="menu-input"
            icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
            id={uid}
            label={name}
            labelRef={dimensionRef}
            name={name}
            onChange={() => {}}
            placeholder={`Select ${name}`}
            ref={targetRef}
            value={selectValue}
            isDisabled={disabled}
            style={{ caretColor: 'transparent' }}
            onClick={changeValueByBlur}
            enableCustomValidation
            hasError={errorText}
            isRequired={required}
            errorMessage={intl.formatMessage(messages.selectBoxMessageError)}
          />
        </div>
        <StyledMenu
          dataTestId="menu-container"
          dimensionRef={dimensionRef}
          labelRef={targetRef}
          onClose={handleOpen}
          onOpen={handleOpen}
          isAuto={false}
          style={{ width: '296px' }}
        >
          {options &&
            options.map((option, key) => (
              <StyledMenuListItem
                dataTestId={`menu-item-${key}`}
                isSelected={option.value === selectValue}
                key={option.value}
                onClick={() => handleSelect(option)}
                hideSelectedItemCheckmark={false}
              >
                <span
                  style={{
                    whiteSpace: 'normal'
                  }}
                >
                  {option.label}
                </span>
              </StyledMenuListItem>
            ))}
        </StyledMenu>
      </Row>
    </StyledInputBoxs>
  );
};

export default DriveSelectBox;
