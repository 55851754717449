/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useIntl } from 'react-intl';
import {
  Dialog,
  BUTTON_VARIANTS,
  SimpleTable,
  SimpleTableBody,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  Row,
  Toast,
  Button,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS,
  IconAddCircle,
  Input,
  BUTTON_SIZES
} from 'cdk-radial';
import { FormEvent, useEffect, useState } from 'react';
import {
  useGetCNumberValidCheckMutation,
  useGetOrganizationsDMSBoxMappingsQuery
} from 'redux/services/eis-external-attributes-service';
import {
  addCNumberModalSaveData,
  addModalSaveData
} from 'helpers/dialogBoxHelper';
import { usePutDeleteAlternateDetailsMutation } from 'redux/services/enterpriseInfo-service';
import { DriveModalSelectBox } from 'components/molecules/common/Inputs';
import { messages as commonMessages } from 'commonMessages';
import { AddIdentifiersDialogProps, Options } from './types';
import { messages as dialogCommonMessages } from '../messages';
import { messages } from './messages';
import { StyledRow, StyledInputBoxContainer } from './styled';
import { CRUD_OPTIONS } from './constants';

export const AddIdentifiersDialog = ({
  handleClose,
  isOpen,
  tableData,
  handleSave,
  filteroptionsdata,
  storeId,
  text
}: AddIdentifiersDialogProps): JSX.Element => {
  const intl = useIntl();
  const [selectNameOptions, setSelectnameOptions] = useState<any>([]);
  const [deleteIdentityTableData, setDeleteIdentityTableData] = useState<any>(
    []
  );
  const [dialogBoxError, setDialogBoxError] = useState<string>('');
  const [selectedName, setSelectedName] = useState<any>('');
  const [errorInputs, setErrorInputs] = useState<Record<number, unknown>[]>([]);
  const { ADD, EDIT, DELETE } = CRUD_OPTIONS;
  const [identityTableData, setIdentityTableData] = useState<
    Record<number, unknown>[]
  >([]);
  const [newIndentityData, setNewindentityData] = useState<
    Record<number, unknown>[]
  >([]);
  const [showAddValueButton, setShowAddValueButton] = useState(false);
  const { refetch } = useGetOrganizationsDMSBoxMappingsQuery(storeId || '', {
    skip: !storeId || storeId === '' || !isOpen
  });
  const [getCNumberValidCheck] = useGetCNumberValidCheckMutation();
  const [putDeleteAlternateDetails] = usePutDeleteAlternateDetailsMutation();

  useEffect(() => {
    setSelectnameOptions(filteroptionsdata);
    setSelectedName('');
    if (isOpen) {
      setNewindentityData([...newIndentityData, '']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteroptionsdata, isOpen]);

  const handleModalClose = () => {
    setIdentityTableData([]);
    setErrorInputs([]);
    setNewindentityData([]);
    setSelectedName('');
    handleClose(false);
    setDialogBoxError('');
    setShowAddValueButton(false);
  };
  const handleModalSaveData = () => {
    addModalSaveData(
      identityTableData,
      newIndentityData,
      selectedName,
      tableData,
      handleSave,
      handleModalClose,
      errorInputs,
      setErrorInputs
    );
  };
  const deleteIndentyValue = (index: any, typeStatus: string) => {
    if (typeStatus === EDIT) {
      const arryData: any = identityTableData[0];
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setIdentityTableData([[...editArr]]);
    } else if (typeStatus === DELETE) {
      const arryData: any = identityTableData[0];
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind === index
      );
      setDeleteIdentityTableData([editArr, ...deleteIdentityTableData]);
    } else {
      const newArr: any = newIndentityData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setNewindentityData([...newArr]);
    }

    const errInp: any = errorInputs.filter((e: unknown) => e !== index);
    setErrorInputs([...errInp]);
  };
  const handleModalCNumberSaveData = () => {
    addCNumberModalSaveData(
      tableData,
      identityTableData,
      newIndentityData,
      errorInputs,
      setErrorInputs,
      selectedName,
      deleteIdentityTableData,
      getCNumberValidCheck,
      text,
      putDeleteAlternateDetails,
      handleModalClose,
      handleSave,
      setDialogBoxError,
      refetch
    );
  };
  const onChangeEditValue = (
    event: FormEvent<HTMLFormElement>,
    index: any,
    typeStatus: string
  ) => {
    if (typeStatus === ADD) {
      newIndentityData[index] = event.currentTarget.value;
      setNewindentityData([...newIndentityData]);
    } else {
      identityTableData[0][index] = event.currentTarget.value;
      setIdentityTableData([...identityTableData]);
    }
  };
  const pushTheData = (typeStatus: string) => {
    if (typeStatus === EDIT) {
      const addarr: any = identityTableData.map((rowdata: any) => [
        ...rowdata,
        ''
      ]);
      setIdentityTableData([...addarr]);
    } else {
      setNewindentityData([...newIndentityData, '']);
    }
  };
  const onSelectOption = (options: Options[]) => {
    const optionname: any = options[0];
    setShowAddValueButton(optionname?.isMultivalued);
    setSelectedName(optionname);
  };
  return (
    <div data-testid="addidentifiers-dialogBox-page">
      <Dialog
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleModalClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick:
              selectedName.value === 'cNumber'
                ? handleModalCNumberSaveData
                : handleModalSaveData,
            text: intl.formatMessage(commonMessages.save),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        dataTestId="addidentifer"
        hideCloseButton
        id="dialog-2"
        isOpen={isOpen}
        onClose={handleClose}
        title={intl.formatMessage(messages.addIdentifier)}
        disableOverlayClicked
        className="dialogContentBox"
      >
        <Row>
          {dialogBoxError && dialogBoxError !== 'success' && (
            <Toast
              actions={[
                {
                  onClick: () => setDialogBoxError(''),
                  text: 'X'
                }
              ]}
              position={TOAST_POSITIONS.INLINE}
              variant={TOAST_VARIANTS.NEGATIVE}
              visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
              content={intl.formatMessage(messages.toastMessageIdentifier, {
                message: dialogBoxError
              })}
              id="toast-1"
            />
          )}
        </Row>
        <Row className="dmsStyle">
          {selectNameOptions && selectNameOptions.length > 0 && (
            <DriveModalSelectBox
              uid={1}
              name={intl.formatMessage(messages.selectNameDialog)}
              isPortalActive={isOpen}
              options={selectNameOptions}
              selectValue={selectedName || ''}
              onChangeAddValue={(OptionItems: Options[]) =>
                onSelectOption(OptionItems)
              }
            />
          )}
        </Row>
        {showAddValueButton ? (
          <>
            <StyledRow>
              <Button
                data-testid="push-data"
                icon={<IconAddCircle />}
                text={intl.formatMessage(dialogCommonMessages.addValue)}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={() => pushTheData(ADD)}
              />
            </StyledRow>

            <SimpleTable aria-label="Table">
              <SimpleTableHead>
                <SimpleTableRow>
                  <SimpleTableHeader textAlign="left" colSpan="1">
                    {intl.formatMessage(dialogCommonMessages.tableValue)}
                  </SimpleTableHeader>
                  <SimpleTableHeader textAlign="left" colSpan="12">
                    {intl.formatMessage(dialogCommonMessages.tableAction)}
                  </SimpleTableHeader>
                </SimpleTableRow>
              </SimpleTableHead>
              <SimpleTableBody>
                {newIndentityData.map((row, index: any) => {
                  const keyIndex = index;
                  return (
                    <SimpleTableRow key={keyIndex}>
                      <SimpleTableData textAlign="left">
                        <StyledInputBoxContainer>
                          <Input
                            data-testid={`add-value-input-${index}`}
                            id="add-value-input"
                            name="add-value"
                            onChange={(event: any) =>
                              onChangeEditValue(event, index, ADD)
                            }
                            label=""
                            value={row || ''}
                            size="small"
                            errorMessage={intl.formatMessage(
                              messages.identifierErrorMessage
                            )}
                            hasError={!!errorInputs.includes(index)}
                            enableCustomValidation
                          />
                        </StyledInputBoxContainer>
                      </SimpleTableData>
                      <SimpleTableData textAlign="left">
                        <Button
                          data-testid={`delete-value-input-${index}`}
                          text={intl.formatMessage(commonMessages.delete)}
                          variant={BUTTON_VARIANTS.SECONDARY}
                          onClick={() => deleteIndentyValue(index, ADD)}
                          size={BUTTON_SIZES.SMALL}
                        />
                      </SimpleTableData>
                    </SimpleTableRow>
                  );
                })}
              </SimpleTableBody>
            </SimpleTable>
          </>
        ) : (
          <Input
            data-testid="add-value-input-0"
            id="add-value-input"
            name="add-value"
            onChange={(event: any) => onChangeEditValue(event, 0, ADD)}
            label=""
            value={newIndentityData[0] || ''}
            size="small"
            errorMessage={intl.formatMessage(messages.identifierErrorMessage)}
            hasError={!!errorInputs.includes('0')}
            enableCustomValidation
          />
        )}
      </Dialog>
    </div>
  );
};

export default AddIdentifiersDialog;
