/* eslint-disable @typescript-eslint/no-unsafe-call,
react/require-default-props, react/jsx-props-no-spreading */
import { useEffect, useMemo } from 'react';
import {
  usePreventTableScrollingRef,
  TextRenderer,
  IconRenderer,
  RatingRenderer
} from 'cdk-radial';
import { AgGridColumnProps, AgGridReact } from 'ag-grid-react';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { Loading } from 'components/organisms/common';
import { SelectionChangedEvent } from 'ag-grid-community';
import StyledTableContent from './styled';
import '../../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
import '../../../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';
import paginationPageSize from '../../../../helpers/tableHelper';
import TableFilterType from '../../../../helpers/types/tableFilterType';
import { filterRows } from '../../../../helpers/filterHeaderHelper';

type TableContentProps = {
  gridApi: any;
  columns: AgGridColumnProps[];
  data?: any[];
  searchValue: string;
  filterSelections: Record<string, any>;
  filterSchema: TableFilterType[];
  setGridApi: (gridApi: any) => void;
  setCurrentPage: (currentPage: number) => void;
  setTotalPages: (totalPages: number) => void;
  setTotalRows: (totalRows: number) => void;
  immutableData?: boolean;
  getRowNodeId?: (row: Record<string, string>) => string;
  rowHeight?: number;
  rowSelection?: string;
  loading?: boolean;
  context?: any;
  tableHeight?: number;
  getRowHeight?: ((params: any) => number) | null;
  onSelectionChanged?: (event: any) => void;
  overlayNoRowsTemplateMessage?: string;
  onFirstDataRendered?: any;
};

let selectedFilters: Record<string, any> = {};

const TableContent = ({
  gridApi,
  setGridApi,
  setCurrentPage,
  setTotalPages,
  setTotalRows,
  columns,
  data = undefined,
  immutableData = false,
  searchValue,
  filterSelections,
  filterSchema,
  rowHeight = 33,
  rowSelection = 'multiple',
  getRowNodeId = (row: Record<string, string>) => row.rowIndex,
  loading = false,
  context = null,
  tableHeight = 340,
  getRowHeight = null,
  onSelectionChanged = (event: SelectionChangedEvent) => {
    event.api.getSelectedRows();
  },
  overlayNoRowsTemplateMessage = 'No Rows To Show',
  onFirstDataRendered
}: TableContentProps): JSX.Element => {
  const propsConfig = immutableData
    ? { immutableData, getRowNodeId }
    : { rowData: data };

  useEffect(() => {
    if (gridApi) {
      gridApi.setQuickFilter(searchValue);

      gridApi.refreshCells({
        force: true
      });
      const displayedRowsCount = gridApi.getDisplayedRowCount();
      setTotalRows(displayedRowsCount);

      if (searchValue && displayedRowsCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [searchValue, gridApi, setTotalRows]);

  const displayedRowCount = gridApi ? gridApi.getDisplayedRowCount() : 0;

  useEffect(() => {
    if (gridApi) {
      if (displayedRowCount === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [displayedRowCount, gridApi]);

  useEffect(() => {
    if (gridApi) {
      selectedFilters = filterSelections;
      gridApi.onFilterChanged();
    }
  }, [filterSelections, gridApi]);

  const tableContentRef: HTMLElement = usePreventTableScrollingRef();
  const isExternalFilterPresent = () => true;

  const doesExternalFilterPass = (node: any) =>
    filterRows(node, selectedFilters, filterSchema);

  const frameworkComponentsExtended = {
    textRenderer: TextRenderer,
    iconRenderer: IconRenderer,
    ratingRenderer: RatingRenderer
  };

  const handleGridReady = (gridParams: any) => {
    setGridApi(gridParams.api);
    setCurrentPage(parseInt(gridParams.api.paginationGetCurrentPage(), 10) + 1);
    setTotalPages(gridParams.api.paginationGetTotalPages());
    setTotalRows(gridParams.api.getDisplayedRowCount());
    gridParams.api.sizeColumnsToFit();
  };

  const handlePaginationChanged = () => {
    if (!gridApi) return;
    setCurrentPage(parseInt(gridApi.paginationGetCurrentPage(), 10) + 1);
    setTotalPages(gridApi.paginationGetTotalPages());
    setTotalRows(gridApi.getDisplayedRowCount());
  };

  const defaultColDef = useMemo(
    () => ({
      enablePivot: true,
      sortable: true,
      filter: false,
      flex: 1,
      autoHeight: true,
      minWidth: 100,
      resizable: true,
      refreshCells: true
    }),
    []
  );
  return (
    <StyledTableContent ref={tableContentRef} dataTestId="manage-user-imports">
      <div className="ag-theme-alpine" style={{ height: tableHeight }}>
        {loading ? (
          <Loading dataTestId="manage-user-imports-loader" />
        ) : (
          <AgGridReact
            defaultColDef={defaultColDef}
            frameworkComponents={frameworkComponentsExtended}
            columnDefs={columns}
            onGridReady={handleGridReady}
            rowSelection={rowSelection}
            animateRows
            onPaginationChanged={handlePaginationChanged}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            onColumnResized={gridApi?.resetRowHeights()}
            {...propsConfig}
            pagination
            {...(getRowHeight ? { getRowHeight } : {})}
            paginationPageSize={paginationPageSize(gridApi)}
            onSelectionChanged={onSelectionChanged}
            paginationAutoPageSize={false}
            suppressPaginationPanel
            rowHeight={rowHeight}
            context={context}
            suppressRowClickSelection
            enableCellTextSelection
            overlayNoRowsTemplate={overlayNoRowsTemplateMessage}
            suppressColumnVirtualisation
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            onFirstDataRendered={onFirstDataRendered}
          />
        )}
      </div>
    </StyledTableContent>
  );
};
export default TableContent;
