import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteTitle: {
    id: 'COMMON.DELETE.DIALOG.TITLE',
    defaultMessage: 'Delete',
    description: 'Delete'
  },
  deleteDialogDescription: {
    id: 'COMMON.DELETE.DIALOG.DESCRIPTION',
    defaultMessage: 'Do you want to delete?.'
  }
});
