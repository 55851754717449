import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  readOnlyName: {
    id: 'COMMON.INPUTS.DRIVEREADONLY.NAME.TEXTBOX',
    defaultMessage: '{name}'
  },
  selectBoxMessageError: {
    id: 'COMMON.INPUTS.DRIVESELECTBOX.REQUIRED.MESSAGE',
    defaultMessage: 'Please Select Required field'
  },
  selectChipName: {
    id: 'COMMON.INPUTS.DRIVESELECTCHIP.LABEL',
    defaultMessage: '{name}'
  },
  textAreaName: {
    id: 'COMMON.INPUTS.DRIVETEXTAREA.LABEL.NAME',
    defaultMessage: '{name}'
  },
  driveTextBoxError: {
    id: 'COMMON.INPUTS.DRIVETEXTBOX.VALID_DOMAIN_MESSAGE',
    defaultMessage: 'Please enter valid domain'
  },
  driveTextBoxRequiredField: {
    id: 'COMMON.INPUTS.DRIVETEXTBOX.REQUIRED.MESSAGE',
    defaultMessage: 'Required field'
  },
  driveTextViewDeptButton: {
    id: 'COMMON.INPUTS.DRIVETEXTBOXWITHBUTTONS.BUTTONTEXT',
    defaultMessage: 'View all departments'
  }
});
