import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addValue: {
    id: 'COMMON.DIALOGBOX.ADD_VALUE',
    defaultMessage: 'Add Value'
  },
  tableValue: {
    id: 'COMMON.DIALOGBOX.TABLE.VALUE',
    defaultMessage: 'Value'
  },
  tableAction: {
    id: 'COMMON.DIALOGBOX.TABLE.ACTION',
    defaultMessage: 'Action'
  }
});
