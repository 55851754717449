import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dmsCnumber: {
    id: 'GRID.DMSACCESS.DMSCNUMBER.COLUMN.DEFINITIONS.CNUMBER',
    defaultMessage: 'C Number'
  },
  alias: {
    id: 'GRID.DMSACCESS.DMSCNUMBER.COLUMN.DEFINITIONS.ALIAS',
    defaultMessage: 'Alias'
  },
  ipAddress: {
    id: 'GRID.DMSACCESS.DMSCNUMBER.COLUMN.DEFINITIONS.IPADDRESS',
    defaultMessage: 'IP Address'
  },
  account: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.ACCOUNT',
    defaultMessage: 'Account'
  },
  profileByAccount: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.PROFILE',
    defaultMessage: 'Profile by Account'
  },
  id: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.ID',
    defaultMessage: 'ID'
  },
  organization: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.ORGANIZATION',
    defaultMessage: 'Organization'
  },
  profileId: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.PROFILEID',
    defaultMessage: 'Profile ID'
  },
  name: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.NAME',
    defaultMessage: 'Name'
  },
  apps: {
    id: 'GRID.DMSACCESS.DMSACCESSCOLUMNS.COLUMN.DEFINITIONS.APPS',
    defaultMessage: 'Apps'
  },
  dmsUserId: {
    id: 'GRID.DMSACCESS.DMSUSERLIST.COLUMN.DEFINITIONS.DMSUSERID',
    defaultMessage: 'DMS User ID'
  },
  dmsName: {
    id: 'GRID.DMSACCESS.DMSUSERLIST.COLUMN.DEFINITIONS.NAME',
    defaultMessage: 'Name'
  },
  email: {
    id: 'GRID.DMSACCESS.DMSUSERLIST.COLUMN.DEFINITIONS.EMAIL',
    defaultMessage: 'Email'
  },
  dmsAccounts: {
    id: 'GRID.DMSACCESS.DMSUSERLIST.COLUMN.DEFINITIONS.DMSACCOUNT',
    defaultMessage: 'DMS Accounts'
  }
});
