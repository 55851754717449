import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  NoMessageToast: {
    id: 'COMMON.DIALOG.TOAST.MESSAGE.EMPTYTABLE',
    defaultMessage: 'No {message} available'
  },
  dialogDMSTitle: {
    id: 'COMMON.DIALOG.MODAL.TITLE.DMSPROFILES',
    defaultMessage: 'Assign New Job Profiles'
  },
  assignButton: {
    id: 'COMMON.DIALOG.BUTTON.TITLE.ASSIGN',
    defaultMessage: 'Assign'
  },
  dialogProfileHeader: {
    id: 'COMMON.DIALOG.HEADER.TITLE.PROFILELIST',
    defaultMessage: 'Profile List ({count})'
  },
  assignDMSAccounts: {
    id: 'COMMON.DIALOG.MODAL.TITLE.DMSACCOUNTS',
    defaultMessage: 'Assign Account'
  },
  accountListHeader: {
    id: 'COMMON.DIALOG.HEADER.TITLE.ACCOUNTLIST',
    defaultMessage: 'Account List ({count})'
  },
  dmsOrganizationTitle: {
    id: 'COMMON.DIALOG.MODAL.TITLE.DMSORGNIZATION',
    defaultMessage: 'Add New Organization'
  },
  organizationListTitle: {
    id: 'COMMON.DIALOG.TABLE.TITLE.DMSORGNIZATIONLIST',
    defaultMessage: 'Organization List ({count})'
  }
});
