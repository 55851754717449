import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  exitTitle: {
    id: 'COMMON.EXITDIALOG.BUTTON.TITLE.CANCEL',
    defaultMessage: 'Cancel'
  },
  saveAndExit: {
    id: 'COMMON.EXITDIALOG.BUTTON.TITLE.EXITSAVE',
    defaultMessage: 'Save & Exit'
  },
  dialogSaveContent: {
    id: 'COMMON.EXITDIALOG.EXITSAVE.CONTENT',
    defaultMessage: '{text}'
  }
});
