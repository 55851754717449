/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Button,
  BUTTON_VARIANTS,
  Dialog,
  SimpleTable,
  SimpleTableBody,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  Row,
  Input,
  IconAddCircle
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { addModalSaveData } from 'helpers/dialogBoxHelper';
import { messages as commonMessages } from 'commonMessages';
import { messages as dialogCommonMessages } from '../messages';
import { EditIdentifiersProps } from './types';
import { messages } from './messages';
import { EDIT } from './constant';
import StyledInputBoxContainer from './styled';

export const EditIdentifiersDialog = ({
  isOpen,
  tableData,
  optionsdata,
  handleSave,
  handleClose
}: EditIdentifiersProps): JSX.Element => {
  const intl = useIntl();
  const [indentyTableData, setIndentyTableData] = useState<
    Record<number, unknown>[]
  >([]);
  const [newIndentityData, setNewindentityData] = useState<
    Record<number, unknown>[]
  >([]);
  const [errorInputs, setErrorInputs] = useState<Record<number, unknown>[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<any>('');
  const [deleteIdentyTableData, setDeleteIndentyTableData] = useState<any>([]);
  const [selectedName, setSelectedName] = useState<any>('');
  const [showAddValueButton, setShowAddValueButton] = useState(false);

  useEffect(() => {
    if (tableData.length > 0) {
      const dmsStateValue: any = tableData[0] && tableData[0]?.tableData;
      const optionname: any = {
        label:
          dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId,
        value: dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId
      };
      setSelectedName(optionname);
    }
    const isMultiValued = optionsdata.find(
      (option: { value: string }) => option.value === tableData[0]
    )?.isMultivalued;
    setShowAddValueButton(isMultiValued);
  }, [optionsdata, tableData]);

  useEffect(() => {
    const arrData: any = tableData[1];
    if (arrData && arrData.length > 0) {
      const arraPush: string[] = [];
      Array.from(arrData).forEach((a: any) => {
        arraPush.push(a.externalSystemId);
      });
      indentyTableData.push(arraPush);
    }
    setIndentyTableData(indentyTableData);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const title: any = optionsdata.find(
      (a: { value: any }) => a.value === tableData[0]
    );
    setSelectedTitle(title?.label);
    if (isOpen) {
      setNewindentityData([...newIndentityData, '']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleModalClose = () => {
    setIndentyTableData([]);
    setErrorInputs([]);
    setNewindentityData([]);
    setSelectedName('');
    setSelectedTitle('');
    handleClose(false);
    setShowAddValueButton(false);
  };

  const pushTheData = (typeStatus: string) => {
    if (typeStatus === 'edit') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const addarr: any = indentyTableData.map((rowdata: any) => [
        ...rowdata,
        ''
      ]);
      setIndentyTableData([...addarr]);
    } else {
      setNewindentityData([...newIndentityData, '']);
    }
  };

  const onChangeEditValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any,
    typeStatus: string
  ) => {
    if (typeStatus === 'add') {
      newIndentityData[index] = event.currentTarget.value;
      setNewindentityData([...newIndentityData]);
    } else {
      indentyTableData[0][index] = event.currentTarget.value;
      setIndentyTableData([...indentyTableData]);
    }
  };

  const deleteIndentyValue = (index: any, typeStatus: string) => {
    if (typeStatus === 'edit') {
      const arryData: any = indentyTableData[0];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setIndentyTableData([[...editArr]]);
    } else if (typeStatus === 'delete') {
      const arryData: any = indentyTableData[0];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind === index
      );
      setDeleteIndentyTableData([editArr, ...deleteIdentyTableData]);
    } else {
      const newArr: any = newIndentityData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setNewindentityData([...newArr]);
    }

    const errInp: any = errorInputs.filter((e: unknown) => e !== index);
    setErrorInputs([...errInp]);
  };

  const handleModalSaveData = () => {
    addModalSaveData(
      indentyTableData,
      newIndentityData,
      selectedName,
      tableData,
      handleSave,
      handleModalClose,
      errorInputs,
      setErrorInputs
    );
  };
  return (
    <div data-testid="editidentifiers-dialogbox-page">
      <Dialog
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleModalClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: handleModalSaveData,
            text: intl.formatMessage(commonMessages.save),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        id="dialog-2"
        isOpen={isOpen}
        onClose={handleModalClose}
        title={intl.formatMessage(messages.editIdentifier)}
        disableOverlayClicked={false}
        dataTestId="editIdentifier"
        className="dialogContentBox"
      >
        <Row>
          <span className="identifierName">
            {intl.formatMessage(messages.selectedIdentifier, {
              title: selectedTitle
            })}
          </span>
        </Row>
        {showAddValueButton ? (
          <>
            <Row className="dialogContent">
              <Button
                data-testid="push-data"
                icon={<IconAddCircle />}
                text={intl.formatMessage(dialogCommonMessages.addValue)}
                variant="secondary"
                onClick={() => pushTheData('edit')}
              />
            </Row>
            <SimpleTable aria-label="Table">
              <SimpleTableHead>
                <SimpleTableRow>
                  <SimpleTableHeader textAlign="left" colSpan="1">
                    {intl.formatMessage(dialogCommonMessages.tableValue)}
                  </SimpleTableHeader>
                  <SimpleTableHeader textAlign="left" colSpan="12">
                    {intl.formatMessage(dialogCommonMessages.tableAction)}
                  </SimpleTableHeader>
                </SimpleTableRow>
              </SimpleTableHead>
              <SimpleTableBody>
                {indentyTableData.map((rowdata: any) => {
                  const rowdataSet: string[] = rowdata;
                  return (
                    rowdataSet &&
                    rowdataSet.map((a, index: any) => {
                      const rowdataName: string = a;
                      const keyIndex = index;
                      return (
                        <SimpleTableRow key={keyIndex}>
                          <SimpleTableData textAlign="left">
                            <StyledInputBoxContainer>
                              <Input
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                data-testid={`pushed-value-input-${index}`}
                                id="add-value-input"
                                name="add-value"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => onChangeEditValue(event, index, EDIT)}
                                label=""
                                value={rowdataName || ''}
                                size="small"
                                errorMessage={intl.formatMessage(
                                  messages.identifierErrorMessage
                                )}
                                hasError={!!errorInputs.includes(index)}
                                enableCustomValidation
                              />
                            </StyledInputBoxContainer>
                          </SimpleTableData>
                          <SimpleTableData textAlign="left">
                            <Button
                              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              data-testid={`delete-data-${index}`}
                              text={intl.formatMessage(commonMessages.delete)}
                              variant="secondary"
                              onClick={() => deleteIndentyValue(index, EDIT)}
                              size="small"
                            />
                          </SimpleTableData>
                        </SimpleTableRow>
                      );
                    })
                  );
                })}
              </SimpleTableBody>
            </SimpleTable>
          </>
        ) : (
          <Input
            data-testid="pushed-value-input-0"
            id="add-value-input"
            name="add-value"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeEditValue(event, 0, EDIT)
            }
            value={indentyTableData[0] || ''}
            size="small"
            errorMessage={intl.formatMessage(messages.identifierErrorMessage)}
            hasError={!!errorInputs.includes('0')}
            enableCustomValidation
          />
        )}
      </Dialog>
    </div>
  );
};
export default EditIdentifiersDialog;
