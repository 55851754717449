/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  Button,
  BUTTON_VARIANTS,
  Dialog,
  SimpleTable,
  SimpleTableBody,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  Row,
  Input,
  IconAddCircle,
  Toast,
  TOAST_POSITIONS,
  TOAST_VISIBILITY_DURATIONS,
  TOAST_VARIANTS
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import { FormEvent, useEffect, useState } from 'react';
import {
  useGetCNumberValidCheckMutation,
  useGetOrganizationsDMSBoxMappingsQuery
} from 'redux/services/eis-external-attributes-service';
import { usePutDeleteAlternateDetailsMutation } from 'redux/services/enterpriseInfo-service';
import { addCNumberModalSaveData } from 'helpers/dialogBoxHelper';
import { messages as commonMessages } from 'commonMessages';
import { messages as dialogCommonMessages } from '../messages';
import { messages } from './messages';
import { EditCNumberProps } from './types';

export const EditCNumberDialog = ({
  isOpen,
  text,
  tableData,
  handleClose,
  storeId,
  handleSave,
  optionsdata
}: EditCNumberProps): JSX.Element => {
  const intl = useIntl();
  const [deleteIdentyTableData, setDeleteIndentyTableData] = useState<any>([]);
  const [dialogBoxError, setDialogBoxError] = useState<string>('');
  const [selectedName, setSelectedName] = useState<any>('');
  const [selectedTitle, setSelectedTitle] = useState<any>('');
  const [indentyTableData, setIndentyTableData] = useState<
    Record<number, unknown>[]
  >([]);
  const [newIndentityData, setNewindentityData] = useState<
    Record<number, unknown>[]
  >([]);
  const [errorInputs, setErrorInputs] = useState<Record<number, unknown>[]>([]);
  const { refetch } = useGetOrganizationsDMSBoxMappingsQuery(storeId || '', {
    skip: !storeId || storeId === '' || !isOpen
  });
  const [getCNumberValidCheck] = useGetCNumberValidCheckMutation();

  const [putDeleteAlternateDetails] = usePutDeleteAlternateDetailsMutation();

  useEffect(() => {
    if (tableData.length > 0) {
      const dmsStateValue: any = tableData[0] && tableData[0]?.tableData;
      const optionname: any = {
        label:
          dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId,
        value: dmsStateValue && dmsStateValue?.dmsCnumber?.[0]?.externalSystemId
      };
      setSelectedName(optionname);
    }
  }, [tableData]);

  useEffect(() => {
    const arrData: any = tableData[1];
    if (arrData && arrData.length > 0) {
      const arraPush: string[] = [];
      Array.from(arrData).forEach((a: any) => {
        arraPush.push(a.externalSystemId);
      });
      indentyTableData.push(arraPush);
    }
    setIndentyTableData(indentyTableData);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const title: any = optionsdata.find(
      (a: { value: any }) => a.value === tableData[0]
    );
    setSelectedTitle(title?.label);
    if (isOpen) {
      setNewindentityData([...newIndentityData, '']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onChangeEditValue = (
    event: FormEvent<HTMLFormElement>,
    index: any,
    typeStatus: string
  ) => {
    if (typeStatus === 'add') {
      newIndentityData[index] = event.currentTarget.value;
      setNewindentityData([...newIndentityData]);
    } else {
      indentyTableData[0][index] = event.currentTarget.value;
      setIndentyTableData([...indentyTableData]);
    }
  };

  const pushTheData = (typeStatus: string) => {
    if (typeStatus === 'edit') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const addarr: any = indentyTableData.map((rowdata: any) => [
        ...rowdata,
        ''
      ]);
      setIndentyTableData([...addarr]);
    } else {
      setNewindentityData([...newIndentityData, '']);
    }
  };

  const handleModalClose = () => {
    setIndentyTableData([]);
    setErrorInputs([]);
    setNewindentityData([]);
    setSelectedName('');
    setSelectedTitle('');
    handleClose(false);
    setDialogBoxError('');
  };

  const handleModalCNumberSaveData = () => {
    addCNumberModalSaveData(
      tableData,
      indentyTableData,
      newIndentityData,
      errorInputs,
      setErrorInputs,
      selectedName,
      deleteIdentyTableData,
      getCNumberValidCheck,
      text,
      putDeleteAlternateDetails,
      handleModalClose,
      handleSave,
      setDialogBoxError,
      refetch
    );
  };

  const deleteIndentyValue = (index: any, typeStatus: string) => {
    if (typeStatus === 'edit') {
      const arryData: any = indentyTableData[0];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setIndentyTableData([[...editArr]]);
    } else if (typeStatus === 'delete') {
      const arryData: any = indentyTableData[0];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const editArr: any = arryData.filter(
        (_e: unknown, ind: number) => ind === index
      );
      setDeleteIndentyTableData([editArr, ...deleteIdentyTableData]);
    } else {
      const newArr: any = newIndentityData.filter(
        (_e: unknown, ind: number) => ind !== index
      );
      setNewindentityData([...newArr]);
    }

    const errInp: any = errorInputs.filter((e: unknown) => e !== index);
    setErrorInputs([...errInp]);
  };
  return (
    <div data-testid="editCNumber-dialogBox-page">
      <Dialog
        buttonsProps={[
          {
            id: 'dialog-2-action-1',
            onClick: handleModalClose,
            text: intl.formatMessage(commonMessages.cancel),
            variant: 'text'
          },
          {
            id: 'dialog-2-action-1',
            onClick: handleModalCNumberSaveData,
            text: intl.formatMessage(commonMessages.save),
            variant: BUTTON_VARIANTS.PRIMARY
          }
        ]}
        id="dialog-2"
        isOpen={isOpen}
        onClose={handleModalClose}
        title={intl.formatMessage(messages.editIdentifier)}
        disableOverlayClicked={false}
        dataTestId="editCnumberIdentifier"
        className="dialogContentBox"
      >
        <Row>
          {dialogBoxError && dialogBoxError !== 'success' && (
            <Toast
              actions={[
                {
                  onClick: () => setDialogBoxError(''),
                  text: 'X'
                }
              ]}
              position={TOAST_POSITIONS.INLINE}
              variant={TOAST_VARIANTS.NEGATIVE}
              visibilityDuration={TOAST_VISIBILITY_DURATIONS.SIX_SECONDS}
              content={intl.formatMessage(messages.toastMessageIdentifier, {
                message: dialogBoxError
              })}
              id="toast-1"
            />
          )}
        </Row>
        <Row>
          <span className="identifierName">
            {intl.formatMessage(messages.selectedIdentifier, {
              title: selectedTitle
            })}
          </span>
        </Row>
        <Row className="dialogContent">
          <Button
            data-testid="push-data"
            icon={<IconAddCircle />}
            text={intl.formatMessage(dialogCommonMessages.addValue)}
            variant="secondary"
            onClick={() => pushTheData('edit')}
          />
        </Row>
        <SimpleTable aria-label="Table">
          <SimpleTableHead>
            <SimpleTableRow>
              <SimpleTableHeader textAlign="left" colSpan="1">
                {intl.formatMessage(dialogCommonMessages.tableValue)}
              </SimpleTableHeader>
              <SimpleTableHeader textAlign="left" colSpan="12">
                {intl.formatMessage(dialogCommonMessages.tableAction)}
              </SimpleTableHeader>
            </SimpleTableRow>
          </SimpleTableHead>
          <SimpleTableBody>
            {indentyTableData.map((rowdata: any) => {
              const rowdataSet: string[] = rowdata;
              return (
                rowdataSet &&
                rowdataSet.map((a, index: any) => {
                  const rowdataName: string = a;
                  const deleteRow: string = deleteIdentyTableData
                    .flat(1)
                    .find((b: string) => b === rowdataName);
                  const keyIndex = index;
                  return (
                    <SimpleTableRow key={keyIndex}>
                      <SimpleTableData textAlign="left">
                        <div style={{ width: '125px' }}>
                          <Input
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            data-testid={`pushed-value-input-${index}`}
                            id="add-value-input"
                            name="add-value"
                            onChange={(event: any) =>
                              onChangeEditValue(event, index, 'edit')
                            }
                            isDisabled={!!deleteRow}
                            label=""
                            value={rowdataName || ''}
                            size="small"
                            errorMessage={intl.formatMessage(
                              messages.identifierErrorMessage
                            )}
                            hasError={!!errorInputs.includes(index)}
                            enableCustomValidation
                          />
                        </div>
                      </SimpleTableData>
                      <SimpleTableData textAlign="left">
                        <Button
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          data-testid={`delete-data-${index}`}
                          text={intl.formatMessage(commonMessages.delete)}
                          isDisabled={!!deleteRow}
                          variant="secondary"
                          onClick={() => deleteIndentyValue(index, 'delete')}
                          size="small"
                        />
                      </SimpleTableData>
                    </SimpleTableRow>
                  );
                })
              );
            })}
          </SimpleTableBody>
        </SimpleTable>
      </Dialog>
    </div>
  );
};

export default EditCNumberDialog;
