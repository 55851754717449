import styled from 'styled-components';
import { Button } from 'cdk-radial';

const StyledDeleteDialog = styled.div`
  .footer-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.75rem;
  }
  .modal-desc {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    max-width: 450px;
  }
`;

export const StyledCancelButton = styled(Button)`
  margin: 0 1rem;
`;
export default StyledDeleteDialog;
