import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  departmentLength: {
    id: 'COMMON.DIALOGBOX.DEPARTMENT_LENGTH',
    defaultMessage: 'Departments ( {length} )'
  },
  departmentId: {
    id: 'COMMON.DIALOGBOX.TABLE.LABEL.DEPARTMENT.ID',
    defaultMessage: 'Department ID'
  },
  departmentLogo: {
    id: 'COMMON.DIALOGBOX.TABLE.LABEL.DEPARTMENT.LOGO',
    defaultMessage: 'Logo Name'
  },
  departmentType: {
    id: 'COMMON.DIALOGBOX.TABLE.LABEL.DEPARTMENT.TYPE',
    defaultMessage: 'Type'
  }
});
