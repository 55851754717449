/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-props-no-spreading */
import { LoginCallback } from '@okta/okta-react';
import {
  Toast,
  TOAST_POSITIONS,
  TOAST_VARIANTS,
  TOAST_VISIBILITY_DURATIONS
} from 'cdk-radial';
import { messages } from 'commonMessages';
import { useIntl } from 'react-intl';

const ErrorHandler = (error: any) => {
  const intl = useIntl();
  const errorData = JSON.stringify(error);
  const isJWTIssuedInFuture = errorData.includes(
    'The JWT was issued in the future'
  );
  const isJWTExpiredAndInvalid = errorData.includes(
    'The JWT expired and is no longer valid'
  );

  return (
    <>
      {(isJWTExpiredAndInvalid || isJWTIssuedInFuture) && (
        <Toast
          id="jwt-error-message"
          data-testid="jwt-error"
          content={intl.formatMessage(messages.jwtError)}
          position={TOAST_POSITIONS.INLINE}
          variant={TOAST_VARIANTS.WARNING}
          visibility={TOAST_VISIBILITY_DURATIONS.INFINITE}
        />
      )}
    </>
  );
};

const LoginCallBackProvider = (props: any): JSX.Element => (
  <LoginCallback {...props} errorComponent={ErrorHandler} />
);

export default LoginCallBackProvider;
