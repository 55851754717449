import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addEntityButton: {
    id: 'COMMON.DIALOGBOX.BUTTON.ADDENTITY',
    defaultMessage: 'Add Entity'
  },
  groupTabsDescription: {
    id: 'COMMON.DIALOGBOX.TABS.DESCRIPTION.GROUP',
    defaultMessage: 'Tabs group description'
  },
  storesTabDialog: {
    id: 'COMMON.DIALOGBOX.TAB.TITLE.STORES',
    defaultMessage: 'Stores'
  },
  departmentsTabTitle: {
    id: 'COMMON.DIALOGBOX.TAB.TITLE.DEPARTMENT',
    defaultMessage: 'Departments'
  },
  groupsTabTitle: {
    id: 'COMMON.DIALOGBOX.TAB.TITLE.GROUPS',
    defaultMessage: 'Groups'
  }
});
