/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createApi } from '@reduxjs/toolkit/query/react';
import { chunk } from 'lodash';
import baseQuery from './baseQuery';
import apiEndPoints from './apiEndPoints';
import {
  StoresDetails,
  EnterpriseDetail,
  DepartmentsDetailsType,
  DepartmentsDetails,
  AllStoresDetailsTypeResponse,
  AllStoresDetailsTypePayload,
  DepartmentsDetailsTypePayload,
  SearchAllEnterpriseResponseType,
  SearchAllEnterprisePayloadType,
  AllStoresDetailsPayloadType
} from '../types/eis-core-service-types';
import { GetEntitiesResponse } from './types';

export const eisCoreServiceAPI = createApi({
  reducerPath: 'eisCoreService',
  baseQuery,
  tagTypes: ['getEnterpriseDetails'],
  endpoints: builder => ({
    searchAllEnterprise: builder.query<
      SearchAllEnterpriseResponseType,
      SearchAllEnterprisePayloadType
    >({
      query: ({ searchValue }) =>
        `${apiEndPoints.getEnterpriseDetails}?searchKeyword=${searchValue}&fetchAll=true`
    }),
    getStoreDetails: builder.query<StoresDetails, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getStoreDetails}/${storeId}?includeDepartments=true`
    }),
    getStoreDetailsNoDeptartment: builder.query<StoresDetails, string>({
      query: (storeId: string) => `${apiEndPoints.getStoreDetails}/${storeId}`
    }),
    getEnterpriseDetails: builder.query<EnterpriseDetail, string>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getEnterpriseDetails}/${enterpriseId}`,
      providesTags: ['getEnterpriseDetails']
    }),
    getAllStores: builder.query<
      AllStoresDetailsTypeResponse,
      AllStoresDetailsTypePayload
    >({
      query: ({ enterpriseId, includeDepartments }) => {
        const includeDepartmentsQuery = includeDepartments
          ? `&includeDepartments=true`
          : '';
        return `${apiEndPoints.getEnterpriseDetails}/${enterpriseId}/stores?fetchAllStores=true${includeDepartmentsQuery}`;
      }
    }),
    getStores: builder.query<
      AllStoresDetailsTypeResponse,
      AllStoresDetailsPayloadType
    >({
      query: ({
        enterpriseId,
        pageDetails,
        includeDepartments,
        storeVisibility,
        storeStatus,
        storeType
      }) => {
        const { offset, limit } = pageDetails;
        const includeDepartmentsQuery = includeDepartments
          ? `&includeDepartments=true`
          : '';
        const storeStatusParam =
          storeStatus?.length > 0 ? `&status=${storeStatus}` : '';
        const storeVisibilityParam = storeVisibility
          ? `&isVisible=${storeVisibility === 'Yes'}`
          : '';
        const storeTypeParam =
          storeType?.length > 0 ? `&storeType=${storeType}` : '';
        const pageQueryParam = `page=${offset}&itemsPerPage=${limit}`;
        return `${apiEndPoints.getEnterpriseDetails}/${enterpriseId}/stores?${pageQueryParam}${includeDepartmentsQuery}${storeVisibilityParam}${storeStatusParam}${storeTypeParam}`;
      }
    }),
    getDepartmentDetailsByIds: builder.query<
      DepartmentsDetailsType,
      DepartmentsDetailsTypePayload
    >({
      queryFn: async (
        { enterpriseId, departmentIds },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const departmentList: string[][] = chunk(departmentIds.split(','), 50);
        const deptDataCollection: DepartmentsDetails[] = [];
        const results = await Promise.all(
          departmentList.map((deptIds: string[]) =>
            fetchWithBQ(
              `${apiEndPoints.getDepartmentsDetailsByIds(
                enterpriseId
              )}?departmentIds=${deptIds.join(',')}`
            )
          )
        );
        const departmentData = deptDataCollection.concat(
          ...results
            .map(result => result.data as DepartmentsDetailsType)
            .map(d => d.departments)
        );
        return {
          data: {
            departments: departmentData
          }
        };
      }
    }),
    getDepartmentDetails: builder.query<DepartmentsDetails, string>({
      query: (departmentId: string) =>
        `${apiEndPoints.getDepartmentDetails}/${departmentId}`
    }),
    getEntities: builder.query<
      GetEntitiesResponse,
      {
        offset: number;
        limit: number;
        search: string;
      }
    >({
      query: ({ offset, limit, search }) =>
        `${apiEndPoints.getEntities}?input=${search}&offset=${offset}&limit=${limit}`
    })
  })
});

export const {
  useSearchAllEnterpriseQuery,
  useGetStoreDetailsQuery,
  useLazyGetStoreDetailsNoDeptartmentQuery,
  useGetEnterpriseDetailsQuery,
  useGetAllStoresQuery,
  useGetStoresQuery,
  useGetDepartmentDetailsByIdsQuery,
  useGetDepartmentDetailsQuery,
  useGetEntitiesQuery,
  useLazyGetDepartmentDetailsQuery
} = eisCoreServiceAPI;
