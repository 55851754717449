import {
  SecondaryButton,
  IconDelete,
  IconFileDownload,
  IconButton
} from 'cdk-radial';
import { useIntl } from 'react-intl';
import StyledImportHeader from './styled';
import { ReviewImportHeaderProps } from './types';
import { messages } from './messages';

const ReviewImportHeader = ({
  onRemoveFromImportList,
  onAssignRoles,
  onAssignStores,
  isDownloadable,
  onDownloadClick,
  disableDownloadButton,
  selectedItems,
  isEdit = false
}: ReviewImportHeaderProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledImportHeader>
      <SecondaryButton
        isDisabled={isEdit || !selectedItems}
        dataTestId="import-remove-button"
        className="customized-button"
        icon={<IconDelete />}
        iconColor="primary"
        onClick={onRemoveFromImportList}
        text={intl.formatMessage(messages.removeUser)}
      />
      <SecondaryButton
        dataTestId="roles-button"
        className="customized-button"
        iconColor="primary"
        onClick={onAssignRoles}
        text={intl.formatMessage(messages.assignRolesButton)}
        isDisabled={!selectedItems}
      />
      <SecondaryButton
        dataTestId="stores-button"
        className="customized-button"
        iconColor="primary"
        onClick={onAssignStores}
        text={intl.formatMessage(messages.assignStoresButton)}
        isDisabled={!selectedItems}
      />
      {isDownloadable && (
        <IconButton
          className="customize-download-icon"
          dataTestId="icon-button"
          icon={<IconFileDownload />}
          onClick={onDownloadClick}
          isDisabled={disableDownloadButton}
        />
      )}
    </StyledImportHeader>
  );
};
export default ReviewImportHeader;
