import { Route, BrowserRouter as Router } from 'react-router-dom';
import { ShellLayout } from 'cdk-radial';
import AppWrapper from 'components/templates/commonAppLayout/AppWrapper';
import { StyledCommonAppLayout } from 'components/templates/commonAppLayout/styled';
import useAccessTokenValidator from 'hooks/useAccessTokenValidator';
import LoginCallBackProvider from './LoginCallBackProvider';

export const AppShell = (): JSX.Element => {
  useAccessTokenValidator();
  return (
    <StyledCommonAppLayout>
      <ShellLayout data-testid="Shell-layout-container-1">
        <Router>
          <Route path="/login/callback" component={LoginCallBackProvider} />
          <AppWrapper />
        </Router>
      </ShellLayout>
    </StyledCommonAppLayout>
  );
};
