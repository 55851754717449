import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  enableDisableDialog: {
    id: 'COMMON.DIALOGBOX.ENABLEDISABLE-STATUS',
    defaultMessage: '{statustext} Store MFA'
  },
  StoreEnableMFADialog: {
    id: 'COMMON.DIALOGBOX.STOREENABLEMFA',
    defaultMessage:
      'Are you sure you want to {status} multi-factor authentication for this store? This will {status} MFA for all the users of this store.'
  }
});
