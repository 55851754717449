import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  groupId: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.DMSUSERID',
    defaultMessage: 'Group Id',
    description: 'Group Id'
  },
  groupName: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.DMSUSERNAME',
    defaultMessage: 'Group Name',
    description: 'group name'
  },
  type: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.TYPE',
    defaultMessage: 'Type',
    description: 'Type'
  },
  entityCount: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ENTITYCOUNT',
    defaultMessage: 'Entity Count',
    description: 'Entity Count'
  },
  entityId: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ENTITYID',
    defaultMessage: 'Entity ID',
    description: 'Entity ID'
  },
  entityName: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ENTITTYNAME',
    defaultMessage: 'Entity Name',
    description: 'Entity Name'
  },
  entityType: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ENITYTYPE',
    defaultMessage: 'Entity Type',
    description: 'Entity Type'
  },
  primaryStore: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.PRIMARYSTORE',
    defaultMessage: 'Primary Store',
    description: 'Primary Store'
  },
  storeId: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ID',
    defaultMessage: 'ID',
    description: 'Id'
  },
  storeName: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.NAME',
    defaultMessage: 'Name',
    description: 'name'
  },
  storeType: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.STORE.TYPE',
    defaultMessage: 'Store Type',
    description: 'Store Type'
  },
  storeCity: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.CITY',
    defaultMessage: 'City',
    description: 'City'
  },
  storeCountry: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.COUNTRY',
    defaultMessage: 'Country',
    description: 'Country'
  },
  storeTimeZone: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.TIMEZONE',
    defaultMessage: 'Time Zone',
    description: 'Time Zone'
  },
  action: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.ACTION',
    defaultMessage: 'Action',
    description: 'Action'
  },
  store: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.STORE',
    defaultMessage: 'Store',
    description: 'Store'
  },
  physical: {
    id: 'GRID.GROUPS.COLUMN.DEFINITIONS.PHYSICAL',
    defaultMessage: 'Physical',
    description: 'Physical'
  }
});
