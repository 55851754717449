import { useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  BaseInputField,
  IconArrowDropDown,
  IconArrowDropUp,
  Input,
  Menu,
  MenuListItem,
  Row
} from 'cdk-radial';
import { Z_INDEX } from 'constants/zindex';
import StyledInputBoxs from './styled';

const StyledInput = styled(Input)`
  ${BaseInputField} {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  z-index: ${Z_INDEX.MENU_INDEX};
`;

const StyledMenuListItem = styled(MenuListItem)`
  z-index: ${Z_INDEX.MENU_INDEX};
`;

export type Options = {
  label: string | JSX.Element;
  value: string | any;
};

interface Props {
  uid: number;
  name: string;
  isPortalActive: boolean;
  options: Options[];
  selectValue: string;
  onChangeAddValue: (options: any) => void;
}

interface ChildProps {
  uid: number;
  name: string;
  options: Options[];
  selectValue: any;
  onChangeAddValue: (options: any) => void;
}

export const DriveInsideSelectBox = ({
  uid,
  name,
  options,
  selectValue,
  onChangeAddValue
}: ChildProps): JSX.Element => {
  const targetRef = useRef();
  const dimensionRef = useRef();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>({
    label: '',
    value: ''
  });

  useEffect(() => {
    if (selectValue) {
      setSelectedOption(selectValue);
    }
  }, [selectValue]);

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelect = (option: any) => {
    onChangeAddValue([option]);
  };

  const selectDropDown = () => (
    <div
      data-testid="drive-selectinsidemodal"
      className="drive-select-inside-modal"
    >
      <div data-testid="single-select-dropdown">
        <StyledInput
          dataTestId="menu-input"
          icon={isOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
          id={uid}
          label={name}
          labelRef={dimensionRef}
          name={name}
          placeholder={name}
          ref={targetRef}
          value={selectedOption.label || ''}
          style={{ caretColor: 'transparent' }}
        />
      </div>
      <StyledMenu
        dataTestId="menu-container"
        dimensionRef={dimensionRef}
        labelRef={targetRef}
        onClose={handleOpen}
        onOpen={handleOpen}
        isAuto={false}
        style={{ width: '296px' }}
      >
        {options &&
          options.map((option, key) => (
            <StyledMenuListItem
              dataTestId={`menu-item-${key}`}
              isSelected={option.value === selectedOption.value}
              key={option.value}
              onClick={() => handleSelect(option)}
              hideSelectedItemCheckmark={false}
            >
              {option.label}
            </StyledMenuListItem>
          ))}
      </StyledMenu>
    </div>
  );

  return (
    <StyledInputBoxs className="styled-input-box">
      <Row className="styled-input-box-row">
        <>{selectDropDown()}</>
      </Row>
    </StyledInputBoxs>
  );
};

const DriveModalSelectBox = ({
  uid,
  name,
  options,
  selectValue,
  isPortalActive,
  onChangeAddValue
}: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isPortalActive) {
      setIsVisible(isPortalActive);
    }
  }, [isPortalActive]);
  return (
    <div data-testid="drive-selectmodal">
      {isVisible && (
        <DriveInsideSelectBox
          uid={uid}
          name={name}
          options={options}
          selectValue={selectValue}
          onChangeAddValue={onChangeAddValue}
        />
      )}
    </div>
  );
};
export default DriveModalSelectBox;
