export type AgGridDialogBoxProps = {
  isOpen: boolean;
  prevTableData: any;
  tableData: any;
  isTableDataLoading: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
};

export type ParamsLogsType = {
  data: {
    id: string;
    name: string;
  };
};

export type ParamsProfLogsType = {
  data: {
    id: string;
    name: string;
    apps: string;
  };
};

export type ParamsAcccLogsType = {
  data: {
    id: string;
    profile: any;
  };
  context: {
    onChangeAccountData: (profile: any, id: string) => void;
  };
};

export const ConvertArrToString = ({
  data: { apps }
}: ParamsProfLogsType): string => {
  let appsString = '';
  if (apps) {
    appsString = apps.toString();
  }
  return appsString;
};

export type Options = {
  label: string | JSX.Element;
  value: string | any;
};
